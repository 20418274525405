import React, { useState,useEffect } from 'react'; 
import Modal from 'react-bootstrap/Modal';
import Header from './Header'
import axios from 'axios'
import Chart from './PlanChart'
import PlansList from './PlansForMyplans'
const MyPlans = () => { 
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState('');
    const [selectedplans, setselectedplans] = useState(null);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [plans, setPlans] = useState([]);

    const [total_invested_value, settotal_invested_value] = useState(0);
    const [total_earnings, settotal_earnings] = useState(0);

    useEffect(() => {
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
              if(!token || !email){
                  window.location.href="/"
              }
              else{
                setToken(token)
                setEmail(email)
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                .then(res=>{
                  if(res.data==='User validation failed'){
                    sessionStorage.clear();
                    window.location.href='/'
                  }
                  else{
                    axios.post("https://backend.aitradeandstake.com/api/get_my_all_plans",{
                        email:email
                    },config)
                    .then(data=>{
                        setPlans(data.data)
                        var value = 0;
                        // var earnings = 0;
                        for(let i=0;i<data.data.length;i++){
                            if(data.data.length>0){
                                value = value+parseFloat(data.data[i].plan_amount)
                                // earnings = earnings+parseFloat(data.data[i].staked_current_value)
                            }
                            if(data.data.length-1==i){
                                settotal_invested_value(value)
                                // settotal_earnings(earnings)
                            }
                        }
                    })
                  }
                })
              }
  },[]);

    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setselectedplans(data)
        setShow(true);
        window.location.href="/payout"
    }
	return ( 
        <>
         <Header/>
		<div className='container my-3'> 
       <div class="card mt-3" style={{width: "100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body">
            <h5 class="card-title mt-3" style={{fontWeight:"700",textAlign:"left"}}>Plans</h5>
            <p style={{color:"#adadae",textAlign:"left"}}>
            Embark on a rewarding journey by investing in our dynamic and enticing plans
            </p>
            <div className='row'>
                <div className='col-md-3'>
                <span>Overview</span>
                    <div class="card py-2 mt-1" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                       <span style={{color:"#adadae"}}>Total Invested Value</span>
                       <span style={{color:"#fcbc0b",fontSize:"20px",fontWeight:"700"}}>$ {total_invested_value}</span>
                    </div>
                    <div class="card py-2 mt-1" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                       <span style={{color:"#adadae"}}>Daily</span>
                       <span style={{color:"#fcbc0b",fontSize:"20px",fontWeight:"700"}}>2% Return</span>
                    </div>
                    <div class="card py-2 mt-1" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                       <span style={{color:"#adadae"}}>Total Purchased Plans</span>
                       <span style={{color:"#fcbc0b",fontSize:"20px",fontWeight:"700"}}>{plans.length}</span>
                    </div>
                </div>   
                <div className='col-md-9' style={{height:"100%"}}>
                <span>Daily Payout Chart</span>
                    <div class="card py-2 mt-1" style={{width: "100%",height:"100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                    <Chart/>
                    </div>
                </div>   
            </div>

            <PlansList/>

            <h5 class="card-title mt-3" style={{fontWeight:"700"}}>My Plans</h5>
                <div className='row'>
                    {plans.map(index=>{
                         const objectId = index._id;
                         const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
                         const dateFromObjectId = new Date(timestamp);
                        return(
                            <div className='col-md-4 col-sm-12 mt-1'>
                            <div class="card py-2" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                            <div className='flex-container to_hide_somewhere' style={{textAlign:"left"}}>
                                    <div className='some m-2 p-2'>
                                        <p class="card-text" style={{color:"#adadae"}}>Plan name : <span style={{color:"#fcbc0b"}}>{index.plan_name}</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Plan ID : <span style={{color:"#fcbc0b"}}>{index._id.slice(0,5)+'..'+index._id.slice(19,24)}</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Start time : <span style={{color:"#fcbc0b"}}>{dateFromObjectId.toLocaleDateString()}</span></p>
                                    </div> 
                                    <div className='some m-2 p-2'>
                                    <p class="card-text" style={{color:"#adadae"}}>Invested amt : <span style={{color:"#fcbc0b"}}>$ {index.plan_amount}</span></p>
                                    {/* <p class="card-text" style={{color:"#adadae"}}>APR : <span style={{color:"#fcbc0b"}}>{index.plan_interest}</span></p> */}
                                    <p class="card-text" style={{color:"#adadae"}}>Daily :<span style={{color:"#fcbc0b"}}> 2% Return</span></p>
                                    <p class="card-text" style={{color:"#adadae"}}>Plan status : {index.plan_status==='Initiated'?<span style={{color:"green"}}>Active</span>:<span style={{color:"green"}}>Completed</span>}</p>
                                    </div> 
                                    </div>    

                                    <div className='flex-container to_hide_somewhere__' style={{textAlign:"left"}}>
                                    <div className='some m-2 p-2'>
                                        <p class="card-text" style={{color:"#adadae"}}>Plan name</p>
                                        <p class="card-text" style={{color:"#adadae"}}>Plan ID </p>
                                        <p class="card-text" style={{color:"#adadae"}}>Start time </p>
                                        <p class="card-text" style={{color:"#adadae"}}>Invested amt </p>
                                        {/* <p class="card-text" style={{color:"#adadae"}}>APR </p> */}
                                        <p class="card-text" style={{color:"#adadae"}}>Daily</p>
                                        <p class="card-text" style={{color:"#adadae"}}>Plan status </p>
                                    </div> 
                                    <div className='some m-2 p-2'>
                                    <p style={{color:"#fcbc0b"}}>{index.plan_name}</p>
                                    <p style={{color:"#fcbc0b"}}>{index._id.slice(0,5)+'..'+index._id.slice(19,24)}</p>
                                    <p style={{color:"#fcbc0b"}}>{dateFromObjectId.toLocaleDateString()}</p>
                                    <p style={{color:"#fcbc0b"}}>$ {index.plan_amount}</p>
                                    {/* <p style={{color:"#fcbc0b"}}>{index.plan_interest}</p> */}
                                    <p style={{color:"#fcbc0b"}}>2% Return</p>
                                    {index.plan_status==='Initiated'?<p style={{color:"green"}}>Active</p>:<p style={{color:"green"}}>Completed</p>}
                                    </div> 
                                    </div>  

                                    <center className='my-3'>
                                        <button type="button" class="btn salon_user_button" onClick={()=>handleShow(index)}>Check Payout</button>
                                        </center>
                            </div>
                        </div>
                        )
                    })}
                </div>

                <div class="card py-2 mt-3" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
            <div className='row'>
                <div className='col-md-4 px-3' style={{borderRight:"3px solid #252936"}}>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}>Reward Bonuses</p>
                <p className='' style={{color:"#adadae"}}>
                Implement referral bonuses to incentivize users to bring in new stakers, fostering community growth
                </p>
                </div>   
                <div className='col-md-4 px-3' style={{borderRight:"3px solid #252936"}}>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}>Risk Mitigation Strategies</p>
                <p className='' style={{color:"#adadae"}}>
                Communicate the measures in place to ensure the security of assets you are holding currently
                </p>
                </div>   
                <div className='col-md-4 px-3'>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}>Regular Updates and Reports</p>
                <p style={{color:"#adadae"}}>
                Transparent reporting builds confidence and encourages long-term engagement
                </p>
                </div>  
            </div>
            </div>
            </div>
            </div>
		</div> 
        </>
	); 
} 
export default MyPlans;