import React, { useState,useEffect } from 'react'; 
import axios from 'axios'
import Header from './Header'
import { Link } from 'react-router-dom'
import copy from "copy-to-clipboard";
import QRCode from "react-qr-code";
var CryptoJS = require("crypto-js");

const Profile = () => { 
    const [ref_count, setref_count] = useState(0);
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');
    const [refid, setRefid] = useState('');
    const [newPassword, setnewPassword] = useState('');
    const [c_new_pass, setc_new_pass] = useState('');
    const [password, setpassword] = useState('');
    const [copiedrefid, setcopiedrefid] = useState(false);
    const [copiedLink, setCopiedLink] = useState(false);

    const copyRefId = (id) =>{
        setcopiedrefid(true)
        copy(id)
        setTimeout(()=>{
            setcopiedrefid(false)
        },2000)
    }
    const copyRefLink= (data) =>{
      setCopiedLink(true)
      copy(data)
      setTimeout(()=>{
        setCopiedLink(false)
      },2000)
  }

    const changeAccPass = () =>{
        setMsg(<i class="fa fa-circle-o-notch fa-spin mt-2" style={{fontSize:'30px',color:"#fff"}}></i>)
        if(newPassword.length>0 && password.length>0 && c_new_pass.length>0){
            if(newPassword===c_new_pass){
          let newPass = newPassword
          let Email = email
          let Password = password
          let encrypted = CryptoJS.AES.encrypt(Email,"key")
          let encrypted1 = CryptoJS.AES.encrypt(Password,"key")
         
          axios.post("https://backend.aitradeandstake.com/api/AccChangePass",
          {
            encrypt1:encrypted.toString(),encrypt2:encrypted1.toString(),newPass:newPass
          })
          .then(res=>{
                  if(res.data){
                    setMsg("Successfully changed password , Kindly login again.")
                      setTimeout(()=>{
                          sessionStorage.clear();
                          window.location.reload();
                      },3000)
                  }
          })
          .catch(err=>{
            setMsg("Wrong old password")
          })
      }
      else{
        setMsg("Entered password doesn't match")
      }
        }
        else{
            setMsg("Kindly enter all details correctly")
        }
  }

    useEffect(() => {
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
              if(!token || !email){
                  window.location.href="/"
              }
              else{
                setEmail(email)
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                .then(async(res)=>{
                  if(res.data==='User validation failed'){
                    sessionStorage.clear();
                    window.location.href='/'
                  }
                  else{
                    setRefid(res.data.data._id)
                    const config = {
                      headers: { Authorization: `Bearer ${token}` }
                    };
                    axios.post("https://backend.aitradeandstake.com/api/get_referral_count",{
                      email:email
                    },config)
                    .then(userData=>{
                      setref_count(userData.data.count)
                    })
                  }
                })
              }
  },[]);
	return ( 
        <>
         <Header/>
		<div className='container mt-3'> 
       <div class="card" style={{width: "100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body">
                <div className='mt-2' style={{textAlign:"left"}}>
                <div className='row'>
                  <div className='col-md-6'>
                        <span style={{color:"#adadae"}}>Hello!</span><br/>
                      <i class="fa fa-user" style={{fontSize:"18px",cursor:"pointer",color:"#fcbc0b",marginRight:"10px"}} aria-hidden="true"></i>
                      <span  style={{fontSize:"19px"}}>{email.substring(0, email.indexOf('@'))}</span><br/>
                      <span style={{color:"#fcbc0b",marginRight:"10px"}}>Reg Info :</span>
                      <span style={{fontSize:""}}>{email}</span><br/>
                      <span style={{color:"#fcbc0b",marginRight:"10px"}}>Referral Count :</span>
                      <span style={{fontSize:""}}>{ref_count}</span><br/>
                      <p style={{color:"#fff",fontWeight:""}}><span style={{color:"#fcbc0b"}}>Referral ID :</span> {refid?refid:''}
                              {
                                  copiedrefid?
                                  <>
                                  <i class="fa fa-copy" onClick={()=>copyRefId(refid?refid:'')} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer",color:"#5b90f4"}} aria-hidden="true"></i>
                                  <span style={{color:"#5b90f4",fontSize:"12px",marginLeft:"5px"}}>Copied!</span>
                                  </>
                                  :
                                  <i class="fa fa-copy" onClick={()=>copyRefId(refid?refid:'')} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer"}} aria-hidden="true"></i>
                              }
                      </p>
                  </div>
                  <div className='col-md-6 text-center'>
                  <h5 class="card-title mt-3" style={{fontWeight:"700"}}>Share Your Referral ID</h5>
                          <QRCode 
                          size={250}
                          style={{ height: "20vh", maxWidth: "100%", width: "100%" }} 
                          value={`https://www.aitradeandstake.com/signup?${refid}`}
                          viewBox={`0 0 256 256`}
                          bgColor={"#000"}
                          fgColor={"#fff"}
                          />
                          <p style={{color:"#fcbc0b",fontWeight:"800",fontSize:"12px"}} className='mt-3'>{`https://www.aitradeandstake.com/signup?${refid}`}</p>
                           <button onClick={()=>copyRefLink(`https://www.aitradeandstake.com/signup?${refid}`)} type="button" class="btn" style={{marginRight:"5px",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>
                            {copiedLink?'Copied!':'Copy Link'}
                        </button>
                  </div>
                </div>
                </div>
                {/* <div className='mt-3' style={{textAlign:"left"}}>
                    <div className='row'>
                        <div className='col-md-6'>
                        <p style={{color:"#adadae",fontSize:"14px",marginTop:"1rem"}}>Account Summary</p><br/>
                        </div>
                        <div className='col-md-6' style={{textAlign:"right"}}>
                          <QRCode 
                          size={250}
                          style={{ height: "20vh", maxWidth: "100%", width: "100%" }} 
                          value={refid}
                          viewBox={`0 0 256 256`}
                          bgColor={"#000"}
                          fgColor={"#fff"}
                          />
                        <Link to="/wallet">
                        <button type="button" class="btn" style={{marginRight:"5px",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>
                            Send Funds
                        </button>
                        </Link>
                        <Link to="/myplans">
                        <button type="button" class="btn salon_user_button" style={{width:""}} >Invest and Earn</button>  
                        </Link>
                        </div>
                    </div>
                </div> */}

                <div className='mt-3' style={{textAlign:"left"}}>
                <div class="card p-4" style={{width: "100%",height:"100%",borderRadius:"6px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                    <div className='row'>
                  <div className='col-md-6 col-sm-12 mt-3'>
                  <div className="form-group mb-6">
                  <label for="exampleInputPassword1" style={{fontWeight:'500'}}>Old Password</label>
                    <input type="password" class="form-control ProI" onChange={(e)=>setpassword(e.target.value)} name="password"  placeholder="Enter Old Password"/>
                </div>
                  </div>
                  <div className='col-md-6 col-sm-12 mt-3'>
                  <div className="form-group">
                  <label for="exampleInputPassword1" style={{fontWeight:'500'}}>New Password</label>
                    <input type="password" class="form-control ProI" name="newPassword" onChange={(e)=>setnewPassword(e.target.value)}  placeholder="Enter New Password"/>
                </div>
                  </div>
                  </div>  
                  <div className='row'>
                  <div className='col-md-6 col-sm-12 mt-3'>
                  <div className="form-group mb-6">
                  <label for="exampleInputPassword1" style={{fontWeight:'500'}}>Confirm New Password</label>
                    <input type="password" class="form-control ProI" onChange={(e)=>setc_new_pass(e.target.value)} name="password"  placeholder="Enter Old Password"/>
                </div>
                  </div>
                  </div>  
                  <center>
                  <button type="button" onClick={changeAccPass} class="btn salon_user_button mt-3" style={{minWidth:"20%"}} >Reset Password</button>  
                  <p className='mt-2'>{msg}</p>
                  </center>
                </div>
                </div>
            </div>

            </div>
		</div> 
        </>
	); 
} 
export default Profile;