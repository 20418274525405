import React, { useState,useEffect } from 'react'; 
import Header from './Header'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import copy from "copy-to-clipboard";
import BSC from "../Images/binance.png"



const Payout = () => { 
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState('');
    const [txn_hash, settxn_hash] = useState('');
    const [network, setnetwork] = useState('');
    const [msg, setMsg] = useState('');
    const [msgWithdraw, setMsgWithdraw] = useState('');
    const [show, setShow] = useState(false);
    const [showwithdraw, setShowwithdraw] = useState(false);
    const [disableBtn, setdisableBtn] = useState(false);
    const [disableBtnWithdraw, setdisableBtnWithdraw] = useState(false);
    const [amountwithdraw, setAmountwithdraw] = useState('');
    const [walletwithdraw, setWalletwithdraw] = useState('');
    const [networkwithdraw, setnetworkwithdraw] = useState('');

    const [refid, setRefid] = useState('');

    const [silver_payout, setsilver_payout] = useState([]);
    const [gold_payout, setgold_payout] = useState([]);
    const [diamond_payout, setdiamond_payout] = useState([]);
    const [platinum_payout, setplatinum_payout] = useState([]);
    const [fifth_payout, setfifth_payout] = useState([]);

    const [silver_payout_sum, setsilver_payout_sum] = useState(0);
    const [gold_payout_sum, setgold_payout_sum] = useState(0);
    const [diamond_payout_sum, setdiamond_payout_sum] = useState(0);
    const [platinum_payout_sum, setplatinum_payout_sum] = useState(0);
    const [fifth_payout_sum, setfifth_payout_sum] = useState(0);

    
    const [selectedDate, setselectedDate] = useState('');
    const [selectedtrade, setselectedtrade] = useState('');
    const [EthPrice, setEthPrice] = useState(0);

    const handleClose = () =>{
        setShowwithdraw(false)
        setShow(false);
        setMsgWithdraw('')
        setMsg('')
    }
    const handleShow = (data) => {
        setShow(true);
        setselectedtrade(data)
        var timeStamp = parseInt(data._id.toString().substr(0,8), 16)*1000
        var date = new Date(timeStamp).toLocaleDateString()
        setselectedDate(date)
    }
    useEffect(() => {
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
              if(!token || !email){
                  window.location.href="/"
              }
              else{
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                .then(res=>{
                  if(res.data==='User validation failed'){
                    sessionStorage.clear();
                    window.location.href='/'
                  }
                  else{
                    setRefid(res.data.data._id)
                    axios.post("https://backend.aitradeandstake.com/api/get_user_balance",{email:email},config)
                    .then(data=>{
                        setBalance(parseFloat(data.data))
                    })
                    firstPlan();
                    secondPlan();
                    thirdPlan();
                    fourthPlan();
                    fifthPlan();
                  }
                })
                axios.get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USDT")
                .then(ethPrice=>{
                    setEthPrice(ethPrice.data.USDT)
                })
              }
  },[]);

  const fifthPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_fifth_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setfifth_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_fifth_plan_payout_sum",{email:email},config)
    .then(res=>{
        setfifth_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }

  const fourthPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_fourth_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setplatinum_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_fourth_plan_payout_sum",{email:email},config)
    .then(res=>{
        setplatinum_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }

  const thirdPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_third_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setdiamond_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_third_plan_payout_sum",{email:email},config)
    .then(res=>{
        setdiamond_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }
  
  const secondPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_second_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setgold_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_second_plan_payout_sum",{email:email},config)
    .then(res=>{
        setgold_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }
  
  const firstPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_first_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setsilver_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_first_plan_payout_sum",{email:email},config)
    .then(res=>{
        setsilver_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }


 
	return ( 
        <>
        <Header/>
		<div className='container my-3'> 

        <div class="card mt-3" style={{width: "100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body">

            <div className='row mt-3'>
            <div className='col-md-3 col-sm-12 mt-1'>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Lite Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {fifth_payout_sum}
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Lite Payout</p>
                        </div>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-1'>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Standard Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {silver_payout_sum}
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Standard Payout</p>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-1'>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Plus Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {gold_payout_sum}  
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Plus Payout</p>
                        </div>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-1'>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Pro Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {diamond_payout_sum}   
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Pro Payout</p>
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                <div className='col-md-3 col-sm-12 mt-1'>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Prime Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {platinum_payout_sum}   
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Prime Payout</p>
                        </div>
                    </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-md-6 mt-1'>
                        <div class="card for_shadow" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
                        <div class="card-body " style={{maxHeight:"50vh",overflow:"scroll"}}>
                            <h5 class="card-title" style={{fontWeight:"700"}}>AI Lite Payout</h5>
                            <div className='table-responsive'style={{borderRadius:"12px"}}>
                            <table class="table table-dark">
                            <thead>
                                <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Network</th>
                                <th scope="col">Time</th>
                                <th scope="col">Status</th>
                                <th scope="col">Fees</th>
                                </tr>
                            </thead>
                            {fifth_payout.map(index=>{
                                var timeStamp = parseInt(index._id.toString().substr(0,8), 16)*1000
                                var date = new Date(timeStamp).toLocaleDateString()
                                return(
                                    <tbody>
                                    <tr onClick={()=>handleShow(index)} style={{cursor:"pointer"}}>
                                    <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                                    <td style={{color:"#fcbc05"}}>$ {index.amount}</td>
                                    <td><img src={BSC} style={{width:"3vh",marginRight:"5px"}}/>BSC</td>
                                    <td>{date}</td>
                                    <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>Deposited</td>
                                    <td style={{color:"",fontWeight:"700",fontSize:"13px"}}>2% of Payout</td>
                                    </tr>
                                </tbody>
                                )
                            })}
                            </table>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className='col-md-6 mt-1'>
                        <div class="card for_shadow" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
                        <div class="card-body " style={{maxHeight:"50vh",overflow:"scroll"}}>
                            <h5 class="card-title" style={{fontWeight:"700"}}>AI Standard Payout</h5>
                            <div className='table-responsive'style={{borderRadius:"12px"}}>
                            <table class="table table-dark">
                            <thead>
                                <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Network</th>
                                <th scope="col">Time</th>
                                <th scope="col">Status</th>
                                <th scope="col">Fees</th>
                                </tr>
                            </thead>
                            {silver_payout.map(index=>{
                                   var timeStamp = parseInt(index._id.toString().substr(0,8), 16)*1000
                                   var date = new Date(timeStamp).toLocaleDateString()                          
                                return(
                                    <tbody>
                                    <tr onClick={()=>handleShow(index)} style={{cursor:"pointer"}}>
                                    <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                                    <td style={{color:"#fcbc05"}}>$ {index.amount}</td>
                                    <td><img src={BSC} style={{width:"3vh",marginRight:"5px"}}/>BSC</td>
                                    <td>{date}</td>
                                    <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>Deposited</td>
                                    <td style={{color:"",fontWeight:"700",fontSize:"13px"}}>2% of Payout</td>
                                    </tr>
                                </tbody>
                                )
                            })}
                            </table>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>

                    <div className='row mt-3'>
                    <div className='col-md-6 mt-1'>
            <div class="card for_shadow" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body" style={{maxHeight:"50vh",overflow:"scroll"}}>
                <h5 class="card-title" style={{fontWeight:"700"}}>AI Plus Payout</h5>
                <div className='table-responsive' style={{borderRadius:"12px"}}>
                <table class="table table-dark">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Network</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Fees</th>
                    </tr>
                </thead>
                {gold_payout.map(index=>{
                        var timeStamp = parseInt(index._id.toString().substr(0,8), 16)*1000
                        var date = new Date(timeStamp).toLocaleDateString()  
                    return(
                        <tbody>
                        <tr onClick={()=>handleShow(index)} style={{cursor:"pointer"}}>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>$ {index.amount}</td>
                        <td><img src={BSC} style={{width:"3vh",marginRight:"5px"}}/>BSC</td>
                        <td>{date}</td>
                        <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>Deposited</td>
                        <td style={{color:"",fontWeight:"700",fontSize:"13px"}}>2% of Payout</td>
                        </tr>
                    </tbody>
                    )
                })}
                </table>
                </div>
            </div>
            </div>
                    </div>
                    <div className='col-md-6 mt-1'>
                    <div class="card for_shadow" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
                    <div class="card-body" style={{maxHeight:"50vh",overflow:"scroll"}}>
                        <h5 class="card-title" style={{fontWeight:"700"}}>AI Pro Payout</h5>
                        <div className='table-responsive'style={{borderRadius:"12px"}}>
                        <table class="table table-dark">
                        <thead>
                            <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Network</th>
                            <th scope="col">Time</th>
                            <th scope="col">Status</th>
                            <th scope="col">Fees</th>
                            </tr>
                        </thead>
                        {diamond_payout.map(index=>{
                                 var timeStamp = parseInt(index._id.toString().substr(0,8), 16)*1000
                                 var date = new Date(timeStamp).toLocaleDateString()  
                            return(
                                <tbody>
                                <tr onClick={()=>handleShow(index)} style={{cursor:"pointer"}}>
                                <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                                <td style={{color:"#fcbc05"}}>$ {index.amount}</td>
                                <td><img src={BSC} style={{width:"3vh",marginRight:"5px"}}/>BSC</td>
                                <td>{date}</td>
                                <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>Deposited</td>
                                <td style={{color:"",fontWeight:"700",fontSize:"13px"}}>2% of Payout</td>
                                </tr>
                            </tbody>
                            )
                        })}
                        </table>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div className='row mt-3'>
                    <div className='col-md-6 mt-1'>
            <div class="card mt-4 for_shadow" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body" style={{maxHeight:"50vh",overflow:"scroll"}}>
                <h5 class="card-title" style={{fontWeight:"700"}}>AI Prime Payout</h5>
                <div className='table-responsive' style={{borderRadius:"12px"}}>
                <table class="table table-dark">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Network</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Fees</th>
                    </tr>
                </thead>
                {platinum_payout.map(index=>{
                     var timeStamp = parseInt(index._id.toString().substr(0,8), 16)*1000
                     var date = new Date(timeStamp).toLocaleDateString()  
                    return(
                        <tbody>
                        <tr onClick={()=>handleShow(index)} style={{cursor:"pointer"}}>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>$ {index.amount}</td>
                        <td><img src={BSC} style={{width:"3vh",marginRight:"5px"}}/>BSC</td>
                        <td>{date}</td>
                        <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>Deposited</td>
                        <td style={{color:"",fontWeight:"700",fontSize:"13px"}}>2% of Payout</td>
                        </tr>
                    </tbody>
                    )
                })}
                </table>
                </div>
            </div>
            </div>
                    </div>
                    </div>

                    <div class="card py-2 mt-5" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
            <div className='row'>
                <div className='col-md-3 px-3' style={{borderRight:"3px solid #252936"}}>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}>Period Completion</p>
                <p className='' style={{color:"#adadae"}}>
                Upon the completion of your plan period, your earned rewards are calculated based on the plan you chose.
                </p>
                </div>   
                <div className='col-md-3 px-3' style={{borderRight:"3px solid #252936"}}>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}>Automated Payouts</p>
                <p className='' style={{color:"#adadae"}}>
                Our platform employs an automated payout system. Your rewards will be credited directly in your account
                </p>
                </div>   
                <div className='col-md-3 px-3' style={{borderRight:"3px solid #252936"}}>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}>Frequency of Payouts</p>
                <p style={{color:"#adadae"}}>
                Payout frequencies vary depending on the plan and congestion in network, Some plans offer daily payouts, while others may follow a 
                weekly schedule
                </p>
                </div>  
                <div className='col-md-3 px-3'>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}>Real-Time Tracking</p>
                <p style={{color:"#adadae"}}>
                Monitor your earnings in real-time through our user-friendly dashboard. Track your plan performance and payouts effortlessly.
                </p>
                </div>  
            </div>
            </div>

        {/* <div className='row mt-3'>
            <div className='col-md-6 mt-1'>
            <div class="card for_shadow" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body " style={{maxHeight:"50vh",overflow:"scroll"}}>
                <h5 class="card-title" style={{fontWeight:"700"}}>AI Standard Payout</h5>
                <div className='table-responsive'style={{borderRadius:"12px"}}>
                <table class="table table-dark">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Network</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Fees</th>
                    </tr>
                </thead>
                {silver_payout.map(index=>{
                    var ti = moment(index.timestamp).format()
                    return(
                        <tbody>
                        <tr onClick={()=>handleShow(index)} style={{cursor:"pointer"}}>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>$ {index.amount}</td>
                        <td><img src={BSC} style={{width:"3vh",marginRight:"5px"}}/>BSC</td>
                        <td>{moment(ti).local().startOf('seconds').fromNow()}</td>
                        <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>Deposited</td>
                        <td style={{color:"",fontWeight:"700",fontSize:"13px"}}>2% of Payout</td>
                        </tr>
                    </tbody>
                    )
                })}
                </table>
                </div>
            </div>
            </div>
            </div>
            <div className='col-md-6 mt-1'>
            <div class="card for_shadow" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body" style={{maxHeight:"50vh",overflow:"scroll"}}>
                <h5 class="card-title" style={{fontWeight:"700"}}>AI Plus Payout</h5>
                <div className='table-responsive' style={{borderRadius:"12px"}}>
                <table class="table table-dark">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Network</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Fees</th>
                    </tr>
                </thead>
                {gold_payout.map(index=>{
                        var ti = moment(index.timestamp).format()
                    return(
                        <tbody>
                        <tr onClick={()=>handleShow(index)} style={{cursor:"pointer"}}>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>$ {index.amount}</td>
                        <td><img src={BSC} style={{width:"3vh",marginRight:"5px"}}/>BSC</td>
                        <td>{moment(ti).local().startOf('seconds').fromNow()}</td>
                        <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>Deposited</td>
                        <td style={{color:"",fontWeight:"700",fontSize:"13px"}}>2% of Payout</td>
                        </tr>
                    </tbody>
                    )
                })}
                </table>
                </div>
            </div>
            </div>
            </div>
        </div>

        <div className='row my-3'>
            <div className='col-md-6'>
            <div class="card mt-4 for_shadow" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body" style={{maxHeight:"50vh",overflow:"scroll"}}>
                <h5 class="card-title" style={{fontWeight:"700"}}>AI Pro Payout</h5>
                <div className='table-responsive'style={{borderRadius:"12px"}}>
                <table class="table table-dark">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Network</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Fees</th>
                    </tr>
                </thead>
                {diamond_payout.map(index=>{
                        var ti = moment(index.timestamp).format()
                    return(
                        <tbody>
                        <tr onClick={()=>handleShow(index)} style={{cursor:"pointer"}}>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>$ {index.amount}</td>
                        <td><img src={BSC} style={{width:"3vh",marginRight:"5px"}}/>BSC</td>
                        <td>{moment(ti).local().startOf('seconds').fromNow()}</td>
                        <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>Deposited</td>
                        <td style={{color:"",fontWeight:"700",fontSize:"13px"}}>2% of Payout</td>
                        </tr>
                    </tbody>
                    )
                })}
                </table>
                </div>
            </div>
            </div>
            </div>
            <div className='col-md-6'>
            <div class="card mt-4 for_shadow" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body" style={{maxHeight:"50vh",overflow:"scroll"}}>
                <h5 class="card-title" style={{fontWeight:"700"}}>AI Prime Payout</h5>
                <div className='table-responsive' style={{borderRadius:"12px"}}>
                <table class="table table-dark">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Network</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Fees</th>
                    </tr>
                </thead>
                {platinum_payout.map(index=>{
                    var ti = moment(index.timestamp).format()
                    return(
                        <tbody>
                        <tr onClick={()=>handleShow(index)} style={{cursor:"pointer"}}>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>$ {index.amount}</td>
                        <td><img src={BSC} style={{width:"3vh",marginRight:"5px"}}/>BSC</td>
                        <td>{moment(ti).local().startOf('seconds').fromNow()}</td>
                        <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>Deposited</td>
                        <td style={{color:"",fontWeight:"700",fontSize:"13px"}}>2% of Payout</td>
                        </tr>
                    </tbody>
                    )
                })}
                </table>
                </div>
            </div>
            </div>
            </div>
        </div> */}
		</div> 
        </div>
		</div> 
        <Modal 
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered      
            show={show} onHide={handleClose}>
            <Modal.Header>
            <Modal.Title>Trade Details </Modal.Title>
            <i class="fa fa-times" style={{cursor:"pointer"}} onClick={handleClose} aria-hidden="true"></i>
            </Modal.Header>
            <Modal.Body style={{paddingBottom:"1.5rem"}}>
            <div className='flex-container' style={{textAlign:"left"}}>
                                    <div className='some m-2 p-2'>
                                        <p class="card-text" style={{color:"#adadae"}}>Pair Name : <span style={{color:"#fcbc0b"}}>ETH/USDT</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Price : <span style={{color:"#fcbc0b"}}>$ {EthPrice}</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Entry Price : <span style={{color:"#fcbc0b"}}>$ {(EthPrice-2.37).toFixed(2)}</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Ranking : <span style={{color:"#fcbc0b"}}>--</span></p>
                                    </div> 
                                    <div className='some m-2 p-2'>
                                        <p class="card-text" style={{color:"#adadae"}}>Trade Amount : <span style={{color:"#fcbc0b"}}>$ {selectedtrade.amount}</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Mark Price : <span style={{color:"#fcbc0b"}}>$ --</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Est. Liq. Price : <span style={{color:"#fcbc0b"}}>--</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Time : <span style={{color:"#fcbc0b"}}>{selectedDate}</span></p>
                                    </div> 
                                    </div>   
        </Modal.Body>
      </Modal>
        </>
	); 
} 
export default Payout;