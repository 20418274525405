import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export default class Landing extends Component {
    render() {
        return (
            <>
            <div className='px-2' style={{backgroundColor:"#fcbc0b",borderRadius:"6px",padding:'0.5%'}}>
                <div className='row' style={{color:"white"}}>
                    <div className='col-md-4 col-sm-12'>
                        <small class="" style={{fontWeight:'700',fontFamily:"sans-serif",color:"#000"}}>2024. AI Trade & Stake All rights reserved.</small><br/>
                    </div>
                    <div className='col-md-8 col-sm-12 ' style={{textAlign:'right'}}>
                    <Link to="/terms" style={{textDecoration:"none"}}>
                    <small class="for_mobile" style={{fontWeight:'900',fontSize:"16px",fontFamily:"sans-serif",color:"#000"}}>Terms conditions</small><br/>
                    </Link>
                    </div>
                </div>
            </div>
            </>
        )
    }
}