import React, { useState,useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import Background from '../../Images/terms.png'
const Terms = () => { 
 
	return ( 
		<div className='container my-3'> 
       <div class="card p-2" style={{textAlign:"left",width: "100%",backgroundColor:"#17161b",color:"#fff"}}>
           <div className='row mt-5'>
               <div className='col-md-6' style={{position:"relative"}}>
                <div className='child' style={{position:"relative",top:"50%",transform:"translateY(-50%)"}}>
               <h2 class="" style={{fontWeight:"900"}}>Legal Terms</h2>
                <p>
                Welcome to AI Trade & Stake, a leading investment platform. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions.
                </p>
                <Link to="/" style={{textDecoration:"none"}}>
                <button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Agree on Terms</button>
                </Link>
                <Link to="/signin" style={{textDecoration:"none",marginLeft:"5px"}}>
                <button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Access Dashboard</button>
                </Link>
                </div>
               </div>
               <div className='col-md-6'>
                <img src={Background} style={{width:"100%"}}/>
               </div>
           </div>

           <div className='row mt-3'>
               <div className='col-md-3 mt-2'>
               <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Acceptance of Terms</p>
               <span style={{color:"#adadae"}}>
               By accessing or using our investment services, you acknowledge that you have read, understood, and accepted these 
               terms and conditions. If you do not agree with any part of these terms, please do not use our services.
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
                 <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Investment Risks</p>
               <span style={{color:"#adadae"}}>
               Investing carries inherent risks, and past performance does not guarantee future results. AI Trade & Stake is not 
               liable for any financial losses incurred through investments. It is essential to carefully assess risks before making 
               investment decisions.
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
                 <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Privacy Policy</p>
               <span style={{color:"#adadae"}}>
               Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our services, 
               you agree to the terms outlined in our Privacy Policy.
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
                 <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Termination of Services</p>
               <span style={{color:"#adadae"}}>
               AI Trade & Stake reserves the right to terminate or suspend services to users at its discretion, for any reason, including but not limited to violation of these terms or illegal activities.
               </span>
                </div>
               </div>
           </div>

           <div className='row mt-3'>
               <div className='col-md-3 mt-2'>
               <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Investment Plans and Services</p>
               <span style={{color:"#adadae"}}>
               AI Trade & Stake offers a range of investment plans and services. The availability of these plans and services is subject to change, and AI Trade & Stake reserves the 
               right to modify or discontinue any offering without prior notice.
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
                 <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>User Responsibilities</p>
               <span style={{color:"#adadae"}}>
               Users of AI Trade & Stake agree to provide accurate and up-to-date information during the account registration process. 
               It is the user's responsibility to ensure the security of their login credentials and promptly notify AI Trade & Stake 
               of any unauthorized access to their account.
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
                 <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Financial Advice</p>
               <span style={{color:"#adadae"}}>
               AI Trade & Stake provides investment-related information, but it does not constitute financial advice. 
               Users are encouraged to seek professional financial advice tailored to their individual circumstances before making any 
               investment decisions.
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
                 <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Fees and Charges</p>
               <span style={{color:"#adadae"}}>
               Users may be subject to fees and charges associated with AI Trade & Stake's services. Detailed information about these 
               fees can be found in the fee schedule provided on our pages. By using our services, users agree to pay any applicable fees.
               </span>
                </div>
               </div>
           </div>

           <div className='row mt-3'>
               <div className='col-md-3 mt-2'>
               <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Compliance with Laws</p>
               <span style={{color:"#adadae"}}>
               Users agree to comply with all applicable laws and regulations when using AI Trade & Stake's services. AI Trade & Stake reserves the right 
               to suspend or terminate the accounts of users who engage in illegal activities or violate these terms.
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
                 <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Limitation of Liability</p>
               <span style={{color:"#adadae"}}>
               AI Trade & Stake is not liable for any direct, indirect, incidental, special, or consequential damages arising from the 
               use of our services. Users acknowledge that investment decisions are at their own risk.
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
                 <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Indemnification</p>
               <span style={{color:"#adadae"}}>
               Users agree to indemnify and hold harmless AI Trade & Stake, its affiliates, and their respective officers, directors, 
               employees, and agents from any claims, losses, damages, liabilities, and expenses arising out of or related to user 
               activities on the platform.
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
                 <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Changes to Terms and Conditions</p>
               <span style={{color:"#adadae"}}>
               AI Trade & Stake may update or modify these terms and conditions at any time. Users will be notified of significant 
               changes, and continued use of the platform after such changes constitutes acceptance of the modified terms.
               </span>
                </div>
               </div>
           </div>

           <div className='row mt-3'>
               <div className='col-md-3 mt-2'>
               <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Deposit and Withdraw</p>
               <span style={{color:"#adadae"}}>
               We pride ourselves on delivering a rapid and responsive financial experience. Expect both deposits and withdrawals to be 
               processed promptly, typically within 24 hours, ensuring you have quick access to your funds. 
               </span>
                </div>
               </div>

               <div className='col-md-3 mt-2'>
               <div class="card p-3 zoom" style={{height:"100%",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
               <p class="" style={{fontWeight:"900"}}>Referral Program</p>
               <span style={{color:"#adadae"}}>
               Referrers and referred users kick off with a 10% bonus on the first deposit, followed by a continuous 5% bonus for referred 
               users from the second deposit onward. Users without a referral link also enjoy a 5% bonus from their initial deposit, 
               ensuring rewards for all.
               </span>
                </div>
               </div>
           </div>

            </div>
		</div> 
	); 
} 
export default Terms;