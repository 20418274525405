import React, { useState } from 'react'; 
import Logo from '../../Images/logo.png'
import Form from './SignupForm'
import '../../CSS/Style.css'
const Signup = () => { 
	return ( 
        <div className='first_sec_ran'>
		<div className='container'> 
        {/* <div className='logo' style={{textAlign:"right"}}> */}
        {/* <img src={Logo} style={{height:"10vh"}}/> */}
        {/* </div> */}
        {/* <div className='child_class'>
       
        </div> */}
        <Form/>
		</div> 
        </div>
	); 
} 
export default Signup;