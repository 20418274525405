import React, { useState,useEffect } from 'react'; 
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
const Plans = () => { 
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState('');
    const [selectedplans, setselectedplans] = useState(null);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [plans, setPlans] = useState([
        {
            name:'AI Lite',
            amount:111,
            interest:'45% - 50%'
        },
        {
            name:'AI Standard',
            amount:200,
            interest:'45% - 50%'
        },
        {
            name:'AI Plus',
            amount:1000,
            interest:'45% - 50%'
        },
        {
            name:'AI Pro',
            amount:3000,
            interest:'45% - 50%'
        },
        {
            name:'AI Prime',
            amount:5000,
            interest:'45% - 50%'
        }
    ]);

    useEffect(() => {
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
              if(!token || !email){
                  window.location.href="/"
              }
              else{
                setToken(token)
                setEmail(email)
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                .then(res=>{
                  if(res.data==='User validation failed'){
                    sessionStorage.clear();
                    window.location.href='/'
                  }
                })
              }
  },[]);

   const handleClose = () => {
        setShow(false);
        setMsg('')
    }
    const handleShow = (data) => {
        setselectedplans(data)
        setShow(true);
    }
    const purchasePlan = () =>{
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
        setMsg(<i class="fa fa-circle-o-notch fa-spin mt-2" style={{fontSize:'30px'}}></i>)
        if(selectedplans!=null){
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.post("https://backend.aitradeandstake.com/api/purchase_plan",{
                email:email,
                plan_name:selectedplans.name,
                plan_amount:selectedplans.amount,
                plan_interest:selectedplans.interest
            },config)
            .then(res=>{
                setMsg(res.data)
            })
        }
        else{
            setMsg("No plans selected")
        }
    }
	return ( 
		<div className='container my-3'> 
       <div class="card" style={{width: "100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body">
            <h5 class="card-title" style={{fontWeight:"700",textAlign:"left"}}>Plans</h5>
                <div className='row'>
                    {plans.map(index=>{
                        return(
                            <div className='col-md-3 col-sm-12 mt-1'>
                            <div class="card py-2 zoom" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                                <span class="card-text" style={{color:"#adadae",fontSize:"20px"}}>{index.name}</span>
                                <hr/>

                                <span class="card-text" style={{fontWeight:"900",fontSize:"25px",color:"#fcbc0b"}}><span style={{fontSize:"18px",color:"#fff"}}>$</span> {index.amount} 
                                <span style={{fontSize:"12px",color:"#adadae"}}>/ Per Subscription</span></span>
                                {/* <span class="card-text" style={{color:"#adadae"}}>APR: {index.interest}</span> */}
                                <span class="card-text" style={{color:"#adadae",fontSize:"16px"}}>Daily 2% Return</span>
                                <br/>
                                <center>
                                <button type="button" class="btn salon_user_button" onClick={()=>handleShow(index)}>Subscribe</button>
                                </center>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
            </div>

            <div className='mt-5'>
				<div class="card p-4" style={{textAlign:"left",width: "100%",height:"100%",borderRadius:"12px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
					<div className='row'>
						<div className='col-md-3 mt-1'>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
							Discover a seamless platform for easy deposits
							</span>
						</div>
						<div className='col-md-3 mt-1'>
							<p style={{fontWeight:"800",color:"#fcbc05"}}>
							Referral bonuses on each deposits
							</p>
							<span style={{color:"#adadae"}}>
							Earn enticing bonuses on each deposit by referring friends, family, or colleagues to join our thriving community.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
						<p style={{fontWeight:"800",color:"#fcbc05"}}>
                            Hassle-free withdrawals
							</p>
							<span style={{color:"#adadae"}}>
							Experience seamless and hassle-free withdrawals with our user-friendly platform. Our streamlined withdrawal 
                            process is designed to provide you with quick access to your funds.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
						<p style={{fontWeight:"800",color:"#fcbc05"}}>
							Automated payouts
							</p>
							<span style={{color:"#adadae"}}>
							Our automated payout system is designed to effortlessly process and distribute your earnings, providing a 
                            seamless and efficient way to access your funds.
							</span>
						</div>
					</div>
				</div>
			</div>

            <Modal 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered      
            show={show} onHide={handleClose}>
            <Modal.Header >
            <Modal.Title>Checkout</Modal.Title>
            <i class="fa fa-times" style={{cursor:"pointer"}} onClick={handleClose} aria-hidden="true"></i>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                <div className='col-md-6 mt-1'>
                <div class="card p-3" style={{width: "100%",backgroundColor:"#212531",color:"#fff"}}>
                <p class="card-text" style={{fontWeight:"900",fontSize:""}}>Choosen Plan</p>
                <span class="card-text" style={{fontWeight:"",color:"#adadae"}}> <span style={{color:"#fcbc0b"}}>{selectedplans?selectedplans.name:''}</span></span>
                {/* <span class="card-text" style={{fontWeight:"",color:"#adadae"}}>Estimated APR: <span style={{color:"#fcbc0b"}}>{selectedplans?selectedplans.interest:''}</span></span>        */}
                <span class="card-text" style={{fontWeight:"",color:"#adadae"}}>Daily 2% Return</span>       
                <span class="card-text" style={{fontWeight:"",color:"#adadae"}}>Payout: <span style={{color:"#fcbc0b"}}>Daily</span></span>      
                </div>
                </div>
                <div className='col-md-6 mt-1'>
                <div class="card p-3" style={{width: "100%",backgroundColor:"#212531",color:"#fff",height:"100%"}}>
                <p class="card-text" style={{fontWeight:"900",fontSize:""}}>Payment Info</p>
                <span class="card-text" style={{fontWeight:"",color:"#adadae"}}>Amount to pay:  <span style={{color:"#fcbc0b"}}>$ {selectedplans?selectedplans.amount:0}</span></span>
                <span class="card-text" style={{fontWeight:"",color:"#adadae"}}>Platform fees: <span style={{color:"#fcbc0b"}}>$ 0.00</span></span>         
                </div> 
                </div>
                <div className='mt-4 text-center'>
                <button type="button" class="btn salon_user_button" style={{width:"40%"}} onClick={purchasePlan}>Subscribe Now!</button>  
                <p>{msg}</p> 
                </div>
            </div>
            </Modal.Body>
            </Modal>
		</div> 
	); 
} 
export default Plans;