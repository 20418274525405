import { BrowserRouter , Route, Routes } from "react-router-dom";
import Dashboard from './Components/Home'
import Wallet from './Components/Wallet'
import Myplans from './Components/MyPlans'
import Mystakes from './Components/MyStakes'
import Payout from './Components/Payout'
import Profile from './Components/Profile'
import Terms from './Components/UnAuthenticated/Terms'
import Signup from './Components/UnAuthenticated/Signup'
import Signin from './Components/UnAuthenticated/Signin'
import Landing from './Components/UnAuthenticated/Landing'
import FogotPassword from './Components/UnAuthenticated/ForgotPassword'
import KYC from './Components/Kyc'
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
    <Routes>
    <Route path ="/" element={<Landing/>} />
    <Route path ="/signin" element={<Signin/>} />
    <Route path ="/signup" element={<Signup/>} />
    <Route path ="/dashboard" element={<Dashboard/>} />
    <Route path ="/wallet" element={<Wallet/>} />
    <Route path ="/myplans" element={<Myplans/>} />
    <Route path ="/mystakes" element={<Mystakes/>} />
    <Route path ="/payout" element={<Payout/>} />
    <Route path ="/terms" element={<Terms/>} /> 
    <Route path ="/profile" element={<Profile/>} /> 
    <Route path ="/forgotpassword" element={<FogotPassword/>} />  
    <Route path ="/kyc" element={<KYC/>} />  
    </Routes>
  </BrowserRouter>
    </div>
  );
}

export default App;
