import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

const PlansForMyplans = () => {
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgPopup, setMsgPopup] = useState('');
    const [selectedplans, setselectedplans] = useState(null);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [amount, setAmount] = useState('');
    const [calculatedapr, setcalculatedapr] = useState(0);
    const [disableBtn, setdisableBtn] = useState(true); // Initially disable button
    const [plans, setPlans] = useState([
        {
            name: 'AI Lite',
            amount: '100 - 500',
            interest: '2'
        },
        {
            name: 'AI Standard',
            amount: '501 - 1000',
            interest: '2'
        },
        {
            name: 'AI Plus',
            amount: '1001 - 5000',
            interest: '2'
        },
        {
            name: 'AI Pro',
            amount: '5001 - 10000',
            interest: '2'
        },
        {
            name: 'AI Prime',
            amount: '10001 - 100000',
            interest: '2'
        }
    ]);

    useEffect(() => {
        let token = sessionStorage.getItem("token");
        let email = sessionStorage.getItem("email");
        if (!token || !email) {
            window.location.href = "/";
        } else {
            setToken(token);
            setEmail(email);
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.get("https://backend.aitradeandstake.com/api/validateToken", config)
                .then(res => {
                    if (res.data === 'User validation failed') {
                        sessionStorage.clear();
                        window.location.href = '/';
                    }
                });
        }
    }, []);

    const resetStates = () => {
        setMsg('');
        setMsgPopup('');
        setselectedplans(null);
        setAmount('');
        setcalculatedapr(0);
        setdisableBtn(true);
    };

    const handleClose = () => {
        setShow(false);
        resetStates();
    };

    const handleShow = (data) => {
        setselectedplans(data);
        setShow(true);
    };

    const purchasePlan = () => {
        let token = sessionStorage.getItem("token");
        let email = sessionStorage.getItem("email");
        setMsg(<i class="fa fa-circle-o-notch fa-spin mt-2" style={{ fontSize: '30px' }}></i>);
        if (selectedplans != null) {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.post("https://backend.aitradeandstake.com/api/purchase_plan", {
                email: email,
                plan_name: selectedplans.name,
                plan_amount: amount,
                plan_interest: selectedplans.interest
            }, config)
                .then(res => {
                    setMsg(res.data);
                });
        } else {
            setMsg("No plans selected");
        }
    };

    const calculateValue = (data) => {
        if (data.length > 0) {
            setAmount(data);
            var calculateVal = (parseFloat(data) * (parseFloat(selectedplans.interest) / 100)).toFixed(2);
            setcalculatedapr(calculateVal);

            const [minAmount, maxAmount] = selectedplans.amount.split(" - ").map(parseFloat);
            const enteredAmount = parseFloat(data);
            if (enteredAmount < minAmount || enteredAmount > maxAmount) {
                setdisableBtn(true);
                setMsgPopup(`Please enter amount between $${minAmount} to $${maxAmount}`);
            } else {
                setdisableBtn(false);
                setMsgPopup('');
            }
        } else {
            setcalculatedapr(0);
            setdisableBtn(true);
            setMsgPopup('');
        }
    };

    return (
        <div className='mt-3'>
            <div class="card" style={{ width: "100%", backgroundColor: "#17161b", border: "1px solid #252936", color: "#fff" }}>
                <div class="card-body">
                    <h5 class="card-title" style={{ fontWeight: "700" }}>Plans</h5>
                    <div className='row'>
                        {plans.map(index => {
                            return (
                                <div className='col-md-3 col-sm-12 mt-1'>
                                    <div class="card py-2 zoom" style={{ width: "100%", backgroundColor: "#212531", border: "1px solid #252936", color: "#fff" }}>
                                        <span class="card-text" style={{ color: "#adadae", fontSize: "20px" }}>{index.name}</span>
                                        <hr />

                                        <span class="card-text" style={{ fontWeight: "900", fontSize: "25px", color: "#fcbc0b" }}><span style={{ fontSize: "18px", color: "#fff" }}>$</span> {index.amount}
                                            <span style={{ fontSize: "12px", color: "#adadae" }}>/ Per Subscription</span></span>
                                        {/* <span class="card-text" style={{color:"#adadae"}}>APR: {index.interest}</span> */}
                                        <span class="card-text" style={{ color: "#adadae" }}>Daily {index.interest}% Return</span>
                                        {/* <span class="card-text" style={{color:"#adadae",fontSize:"13px"}}>Payout: Daily</span> */}
                                        <br />
                                        <center>
                                            <button type="button" class="btn salon_user_button" onClick={() => handleShow(index)}>Subscribe</button>
                                        </center>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Checkout</Modal.Title>
                    <i class="fa fa-times" style={{ cursor: "pointer" }} onClick={handleClose} aria-hidden="true"></i>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-6 mt-1'>
                            <div class="card p-3" style={{ width: "100%", backgroundColor: "#212531", color: "#fff" }}>
                                <p class="card-text" style={{ fontWeight: "900", fontSize: "" }}>Choosen Plan</p>
                                <span class="card-text" style={{ fontWeight: "", color: "#adadae" }}> <span style={{ color: "#fcbc0b" }}>{selectedplans ? selectedplans.name : ''}</span></span>
                                <p className='mt-2'>
                                <input type="number" class="form-control" onChange={(e) => calculateValue(e.target.value)} placeholder="Enter amount" />
                                <small style={{color:"red"}}>{msgPopup}</small>
                                </p>
                                <span class="card-text" style={{ fontWeight: "", color: "#adadae" }}>Estimated Return/Day: <span style={{ color: "#fcbc0b" }}>$ {calculatedapr ? calculatedapr : 0}</span></span>
                                <span class="card-text" style={{ fontWeight: "", color: "#adadae" }}>Daily {selectedplans?selectedplans.interest:0}% Return</span>
                                <span class="card-text" style={{ fontWeight: "", color: "#adadae" }}>Payout: <span style={{ color: "#fcbc0b" }}>Daily</span></span>
                            </div>
                        </div>
                        <div className='col-md-6 mt-1'>
                            <div class="card p-3" style={{ width: "100%", backgroundColor: "#212531", color: "#fff", height: "100%" }}>
                                <p class="card-text" style={{ fontWeight: "900", fontSize: "" }}>Payment Info</p>
                                <span class="card-text" style={{ fontWeight: "", color: "#adadae" }}>Amount to pay:  <span style={{ color: "#fcbc0b" }}>$ {selectedplans ? selectedplans.amount : 0}</span></span>
                                <span class="card-text" style={{ fontWeight: "", color: "#adadae" }}>Platform fees: <span style={{ color: "#fcbc0b" }}>$ 0.00</span></span>
                            </div>
                        </div>
                        <div className='mt-4 text-center'>
                            <button type="button" class="btn salon_user_button" style={{ width: "40%" }} onClick={purchasePlan} disabled={disableBtn}>Subscribe Now!</button>
                            <p>{msg}</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default PlansForMyplans;
