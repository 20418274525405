import React, { useState,useEffect } from 'react'; 
import axios from 'axios'
import { Link } from 'react-router-dom'
import copy from "copy-to-clipboard";

const Investment = () => { 
    const [balance, setBalance] = useState(0);
    // const [activeplans, setActiveplans] = useState(0);
    // const [investedvalue, setInvestedvalue] = useState(0);
    // const [stakeplans, setstakeplans] = useState(0);
    const [email, setEmail] = useState('');
    const [deposits, setDeposits] = useState(0);
    const [totalDeposits, settotalDeposits] = useState(0);
    const [withdraws, setwithdraws] = useState(0);
    const [totalWithdraws, settotalWithdraws] = useState(0);
    const [allpayouts, setAllPyouts] = useState(0);
    const [refid, setRefid] = useState('');
    const [copiedrefid, setcopiedrefid] = useState(false);

    const copyRefId = (id) =>{
        setcopiedrefid(true)
        copy(id)
        setTimeout(()=>{
            setcopiedrefid(false)
        },2000)
    }

    useEffect(() => {
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
              if(!token || !email){
                  window.location.href="/"
              }
              else{
                setEmail(email)
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                .then(async(res)=>{
                  if(res.data==='User validation failed'){
                    sessionStorage.clear();
                    window.location.href='/'
                  }
                  else{
                    setRefid(res.data.data._id)
                    axios.post("https://backend.aitradeandstake.com/api/get_user_balance",{email:email},config)
                    .then(data=>{
                        setBalance(parseFloat(data.data))
                    })
                    // axios.post("https://backend.aitradeandstake.com/api/get_user_active_plans",{email:email},config)
                    // .then(data=>{
                    //     setActiveplans(parseFloat(data.data))
                    // })
                    // axios.post("https://backend.aitradeandstake.com/api/get_user_invested_fund",{email:email},config)
                    // .then(data=>{
                    //     setInvestedvalue(parseFloat(data.data))
                    // })
                    // axios.post("https://backend.aitradeandstake.com/api/get_user_active_staking_plans",{email:email},config)
                    // .then(data=>{
                    //     setstakeplans(parseFloat(data.data))
                    // })
                    axios.post("https://backend.aitradeandstake.com/api/get_withdraw_req",{email:email},config)
                    .then(data=>{
                        if(data.data.length>0){
                            var value = 0;
                            for(let i=0;i<data.data.length;i++){
                                if(data.data[i].status==="Approved"){
                                    value = value+parseFloat(data.data[i].amount)
                                }
                                if(i==data.data.length-1){
                                    setwithdraws(value)
                                    settotalWithdraws(i+1)
                                }
                            }
                        }
                        else{
                            settotalWithdraws(0)
                            setwithdraws(0)
                        }
                    })
                    axios.post("https://backend.aitradeandstake.com/api/get_user_deposit_request",{email:email},config)
                    .then(data=>{
                        if(data.data.length>0){
                            var value = 0;
                            for(let i=0;i<data.data.length;i++){
                                if(data.data[i].status==="Approved"){
                                    value = value+parseFloat(data.data[i].amount)
                                }
                                if(i==data.data.length-1){
                                    setDeposits(value)
                                    settotalDeposits(i+1)
                                }
                            }
                        }
                        else{
                            settotalDeposits(0)
                            setDeposits(0)
                        }
                    })
                  }
                })
                axios.post("https://backend.aitradeandstake.com/api/get_first_plan_payout_sum",{email:email},config)
                .then(async(firstPayout)=>{
                    await axios.post("https://backend.aitradeandstake.com/api/get_second_plan_payout_sum",{email:email},config)
                    .then(async(secondPayout)=>{
                        await axios.post("https://backend.aitradeandstake.com/api/get_third_plan_payout_sum",{email:email},config)
                        .then(async(thirdPayout)=>{
                            await axios.post("https://backend.aitradeandstake.com/api/get_fourth_plan_payout_sum",{email:email},config)
                            .then(async(fourthPayout)=>{
                                await axios.post("https://backend.aitradeandstake.com/api/get_fifth_plan_payout_sum",{email:email},config)
                                .then(async(fifthPayout)=>{
                                setAllPyouts(parseFloat(firstPayout.data)+parseFloat(secondPayout.data)+parseFloat(thirdPayout.data)+parseFloat(fourthPayout.data)+parseFloat(fifthPayout.data))
                                })
                            })
                        })
                    })
                })
              }
  },[]);
	return ( 
        <>
        <div className="marquee-container">
        <div className="marquee-content">
          <span style={{color:"#fcbc05",fontWeight:"800"}}>
			  Enjoy a 10% bonus on your first deposit. Invite friends, and both of you receive 10% bonuses on their first deposits!
			  Plus, continue earning 5% bonuses on every deposit thereafter. </span>
        </div>
      </div>
      {/* <br/> */}
      {/* <div className="marquee-container">
        <div className="marquee-content">
			  <span style={{color:"#fcbc05",fontWeight:"800"}}>Fund Accumulation : <span >$10,000,00</span></span>
        </div>
      </div> */}
		<div className='container mt-3'> 
       <div class="card" style={{width: "100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body">
                <div className='mt-2' style={{textAlign:"left"}}>
                <span style={{color:"#adadae"}}>Hello!</span><br/>
                <i class="fa fa-user" style={{fontSize:"18px",cursor:"pointer",color:"#fcbc0b",marginRight:"10px"}} aria-hidden="true"></i>
                <span style={{fontSize:"19px"}}>{email.substring(0, email.indexOf('@'))}</span><br/>
                <span style={{color:"#fcbc0b",marginRight:"10px"}}>Reg Info :</span>
                <span style={{fontSize:""}}>{email}</span><br/>
                <p style={{color:"#fff",fontWeight:""}}><span style={{color:"#fcbc0b"}}>Referral ID :</span> {refid?refid:''}
                        {
                            copiedrefid?
                            <>
                            <i class="fa fa-copy" onClick={()=>copyRefId(refid?refid:'')} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer",color:"#5b90f4"}} aria-hidden="true"></i>
                            <span style={{color:"#5b90f4",fontSize:"12px",marginLeft:"5px"}}>Copied!</span>
                            </>
                            :
                            <i class="fa fa-copy" onClick={()=>copyRefId(refid?refid:'')} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer"}} aria-hidden="true"></i>
                        }
                </p>
                </div>
                <div className='mt-3' style={{textAlign:"left"}}>
                    <div className='row'>
                        <div className='col-md-6'>
                        <p style={{color:"#adadae",fontSize:"14px",marginTop:"1rem"}}>Account Summary</p><br/>
                        </div>
                        <div className='col-md-6 to_hide_somewhere__' style={{textAlign:"right"}}>
                        <Link to="/wallet">
                        <button type="button" class="btn" style={{marginRight:"5px",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>
                            Send Funds
                        </button>
                        </Link>
                        <Link to="/profile">
                        <button type="button" class="btn salon_user_button" style={{width:""}} >Share and Earn</button>  
                        </Link>
                        </div>
                    </div>
                </div>

                <div className='mt-3' style={{textAlign:"left"}}>
                <div class="card p-4" style={{width: "100%",height:"100%",borderRadius:"6px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                    <div className='row'>
                        <div className='col-md-6'>
                        <p style={{color:"",marginBottom:"0.7rem"}}>Total Assets Net Worth</p>
                        <span class="" style={{fontWeight:"900",fontSize:"30px",color:"#fcbc0b"}}>$ {balance.toFixed(2)}</span>
                        </div>
                        <div className='col-md-6 to_hide_somewhere__' style={{textAlign:"right"}}>
                        <Link to="/wallet">
                        <button type="button" class="btn" style={{marginRight:"5px",border:"",backgroundColor:"green",color:"#fff"}}>
                            Deposit
                        </button>
                        </Link>
                        <Link to="/wallet">
                        <button type="button" class="btn" style={{marginRight:"5px",border:"",backgroundColor:"red",color:"#fff"}} >Withdraw</button>  
                        </Link>
                        </div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col-md-3 mt-1'>
                        <span style={{color:"#adadae"}}>Profits<span style={{color:"green"}}> (Overall)</span></span><br/>
                        <span class="" style={{fontWeight:"900",fontSize:"18px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                        {allpayouts.toFixed(2)}    
                        </span></span>
                        </div>
                        <div className='col-md-3 mt-1'>
                        <span style={{color:"#adadae"}}>Total Number of Deposits</span><br/>
                        <span style={{color:"#fff",fontWeight:"900",fontSize:"18px"}}>
                        {totalDeposits}    
                        </span>
                        </div>
                        <div className='col-md-3 mt-1'>
                        <span style={{color:"#adadae"}}>Total Number of Withdrawals</span><br/>
                        <span style={{color:"#fff",fontWeight:"900",fontSize:"18px"}}>
                        {totalWithdraws}    
                        </span>
                        </div>
                    </div>
                </div>
                </div>
                

                {/* <h5 class="card-title mt-3" style={{fontWeight:"700"}}>Dashboard</h5> */}
                <div className='row mt-3'>
                    <div className='col-md-4 col-sm-12 mt-1'>
                        <Link to="/wallet" style={{textDecoration:"none",color:"#000"}}>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
                            <p class="card-text" style={{color:"#adadae"}}>Main Wallet Balance</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {balance.toFixed(2)}    
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>Available Credit</p>
                        </div>
                        </Link>
                    </div>
                    <div className='col-md-4 col-sm-12 mt-1'>
                    <Link to="/wallet" style={{textDecoration:"none",color:"#000"}}>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>Total USDT Deposits</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {deposits.toFixed(2)}    
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>Account Deposits</p>
                        </div>
                        </Link>
                    </div>
                    <div className='col-md-4 col-sm-12 mt-1'>
                    <Link to="/wallet" style={{textDecoration:"none",color:"#000"}}>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid red"}}>
                            <p class="card-text" style={{color:"#adadae"}}>Total USDT Withdrawals</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {withdraws.toFixed(2)}    
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>Account Withdrawals</p>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>

            </div>
		</div> 
        </>
	); 
} 
export default Investment;