import React, { useState } from 'react'; 
import Logo from '../Images/logo.png'
import { Link } from 'react-router-dom'

const Header = () => { 
    const Logout = ()=>{
      sessionStorage.clear()
      window.location.href="/"
    }
	return ( 
		<div> 
	<nav class="navbar navbar-expand-lg " style={{backgroundColor:""}}>
  <div class="container-fluid">
    <a class="navbar-brand" href="/dashboard" style={{color:"#fff",fontWeight:"900"}}><img className='mob_logo' src={Logo} style={{width:"14vw"}}/></a>
    <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
    <li class="nav-item mt-2">
      <Link to="/dashboard" style={{textDecoration:"none",marginRight:"10px"}}> 
      <button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>
      <i class="fa fa-home" style={{fontSize:"20px",cursor:"pointer"}} aria-hidden="true"></i>
      </button>
      </Link>
        </li>

        <li class="nav-item mt-2">
        <Link to="/wallet" style={{textDecoration:"none",marginRight:"10px"}}> 
        <button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Wallet</button>
        </Link>
        </li>

        <li class="nav-item mt-2">
        <Link to="/myplans" style={{textDecoration:"none",marginRight:"10px"}}> 
        <button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Plans</button>
        </Link>
        </li>

        <li class="nav-item mt-2">
        <Link to="/mystakes" style={{textDecoration:"none",marginRight:"10px"}}> 
        <button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Stakes</button>
        </Link>
        </li>

        <li class="nav-item mt-2">
        <Link to="/kyc" style={{textDecoration:"none",marginRight:"10px"}}> 
        <button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>KYC</button>
        </Link>
        </li>

        <li class="nav-item mt-2">
        <Link to="/profile" style={{textDecoration:"none",marginRight:"10px"}}> 
        <button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}><span style={{fontSize:"15px",cursor:"pointer",marginRight:"10px"}}><i class="fa fa-user" aria-hidden="true"></i></span>Profile</button>
        </Link>
        </li>

        <li class="nav-item mt-2">
        <button type="button" onClick={Logout} class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>
        <span>
          <i class="fa fa-power-off" style={{fontSize:"20px",cursor:"pointer"}} aria-hidden="true"></i>
        </span>
        </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
		</div> 
	); 
} 
export default Header;
