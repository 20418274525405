import React, { useState,useEffect } from 'react'; 
import Header from './Header'
import Plans from './Plans'
import Investment from './Investment'
import MyPayoutDashboard from './MyPayoutDashboard'
// import Stake from './Stake'
import '../CSS/Style.css'
import axios from 'axios'

const Home = () => { 
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    useEffect(() => {
            let token = sessionStorage.getItem("token")
            let email = sessionStorage.getItem("email")
                  if(!token || !email){
                      window.location.href="/signin"
                  }
                  else{
                    setToken(token)
                    setEmail(email)
                    const config = {
                      headers: { Authorization: `Bearer ${token}` }
                  };
                    axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                    .then(res=>{
                      if(res.data==='User validation failed'){
                        sessionStorage.clear();
                        window.location.href='/signin'
                      }
                    })
                  }
      },[]);
	return ( 
		<div style={{overflow:"scroll"}}> 
      <Header/>
      <Investment/>
      <MyPayoutDashboard/>
      {/* <Plans/> */}
      {/* <Stake/> */}
		</div> 
	); 
} 
export default Home;
