import React, { useState,useRef } from 'react'; 
import { Link } from 'react-router-dom';
import Plans from '../../Images/invest.png'
import Stake from '../../Images/stake_l.png'
import Footer from '../Footer'
import Navbar from './Navbar'
import Cominsoon from '../../Images/coming_soon.png'
import Banner from '../../Images/banner_cropped.gif'
import CountUp from 'react-countup';

const Landing = () => { 
	const section1plansRef = useRef(null);
  	const section2stakeRef = useRef(null);
	const section3ReferralRef = useRef(null);
	return ( 
		<>
		<Navbar section1plansRef={section1plansRef} section2stakeRef={section2stakeRef} section3ReferralRef={section3ReferralRef}/>
		<div className="marquee-container">
        <div className="marquee-content">
          <span style={{color:"#fcbc05",fontWeight:"800"}}>
			  Enjoy a 10% bonus on your first deposit. Invite friends, and both of you receive 10% bonuses on their first deposits!
			  Plus, continue earning 5% bonuses on every deposit thereafter. </span>
        </div>
      </div>
	  {/* <br/>
	  <div className="marquee-container">
        <div className="marquee-content">
			  <span style={{color:"#fcbc05",fontWeight:"800"}}>Fund Accumulation : <span >$10,000,00</span></span>
        </div>
      </div> */}
	  <div className='container mt-5'>
				<div class="card coming_soon p-4 mb-4" data-aos="zoom-in-up" 
				style={{width: "100%",height:"50vh",backgroundImage:`url(${Banner})`,borderRadius:"12px",
				border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05",backgroundSize:"cover",
				position:"relative"}}>
				{/* <video autoPlay={true} loop muted className="bg-vid" style={{width:"100%"}}>
					<source src={Banner} type="video/mp4" style={{width:"100%"}} /> 
				</video> */}
				<div className='' style={{textAlign:"center",position:"absolute",left:"50%",top:"50%",
				transform:"translate(-50%, -50%)",fontFamily:"system-ui"}}>
				<span style={{fontSize:"30px",fontWeight:"900"}}>Total Fund Raised</span>
				<br/>
				<span style={{fontSize:"40px",fontWeight:"900",color:"green"}}>
					<CountUp
					start={0.00}
					end={100}
					duration={2.75}
					separator=" "
					decimals={4}
					decimal=","
					prefix="$"
					//   suffix=" left"
					></CountUp>
				</span>
				</div>
				{/* <img src={Banner} style={{width:"100%",height:"auto"}}/> */}
				</div>
			</div>
	  <div className='first_sec_ran'>
		<div className='container py-3' style={{color:"#fff",textAlign:"left"}}> 
            <div className="row mt-5">
				<div className="col-md-6" data-aos="zoom-out-up">
					<div className="">
					<span className="desktop" style={{fontSize:"60px",fontWeight:"900"}}>
					Wealth Woven, 
					<span style={{color:"#fcbc05"}}>
					Dreams Unleashed
					</span>
					</span>
					</div>
				</div>
				<div className="col-md-6 mt-5" data-aos="zoom-out-down" style={{position:"relative"}}>
					<div className="px-3" style={{borderLeft:"10px solid #fff",position:"relative",top:"50%",transform:"translateY(-50%)"}}>
					<p>
					We believe in empowering our users through cutting-edge technology, real-time market insights, and a secure environment for managing your portfolio
					</p>
					<Link to="/signup" style={{textDecoration:"none"}}>
					<button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Signup</button>
					</Link>
					<br/>
					{/* <br/>
					<p style={{fontWeight:"900"}}>
					Fund Accumulation : <span style={{color:"#fcbc05"}}>$1,000,00</span>
					</p> */}
					</div>
				</div>
			</div> 

			<div className="row mt-5">
				<div className="col-md-6 mt-1">
							<div class="card p-4 zoom" style={{width: "100%",height:"100%",borderRadius:"12px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
                               <div className="row">
								   <div className="col-md-6">
										<p style={{fontSize:"30px",fontWeight:"800"}}>
											Invest in Exciting Plans
										</p>
										<span style={{color:"#adadae"}}>
											Explore high-potential investment opportunities and experience the excitement of financial 
											growth with our diverse and dynamic plans.
										</span>
								   </div>
								   <div className="col-md-6">
									   <img src={Plans} style={{width:"100%"}}/>
									</div>
							   </div>
                                <center className="mt-4">
								<Link to="/signin" style={{textDecoration:"none"}}>
								<button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Explore More</button>
								</Link>                                
								</center>
                            </div>
				</div>
				<div className="col-md-6 mt-1">
				<div class="card p-4 zoom" style={{width: "100%",height:"100%",borderRadius:"12px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
                               <div className="row">
								   <div className="col-md-6">
										<p style={{fontSize:"30px",fontWeight:"800"}}>
											The future is here now stake your crypto with AI
										</p>
										<span style={{color:"#adadae"}}>
										Invest in our staking plans to witness the simultaneous growth of your assets and the blockchain 
										networks they support, providing a unique opportunity for financial returns.
										</span>
								   </div>
								   <div className="col-md-6">
									   <img src={Stake} style={{width:"100%"}}/>
									</div>
							   </div>
                                <center className="mt-4">
								<Link to="/signin" style={{textDecoration:"none"}}>
								<button type="button" class="btn" style={{border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Explore More</button>
								</Link>                                
								</center>
                            </div>
				</div>
			</div>

			<div className='mt-5' ref={section1plansRef}>
				<div class="card p-4" style={{width: "100%",height:"100%",borderRadius:"12px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
					<center>
					<span style={{fontSize:"30px",fontWeight:"800"}}>
						Subscription Plans
					</span>
					<p style={{color:"#adadae",fontSize:"14px"}}>
					Investing in exciting plans holds the promise of not only financial growth but also the thrill of participating in 
					ventures that spark curiosity and potential
					</p>
					</center>
					<div className='row'>
					<div className='col-md-3 mt-1'>
							<Link to="/signin" style={{textDecoration:"none"}}>
							<div class="card p-4 zoom card_back" style={{width: "100%",height:"100%",borderRadius:"12px",border:"1px solid #252936",color:"#fff"}}>
							<p style={{color:"#adadae",fontSize:"20px"}}>
							AI Lite
							</p>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
								$100 - 500<span style={{fontSize:"15px",color:"#fff",fontWeight:""}}> /Per Subscription</span>
							</span>
							<span style={{color:"#adadae",marginTop:"1rem"}}>
								Daily 2% Return
							</span>
							</div>
							</Link>
						</div>
						<div className='col-md-3 mt-1'>
							<Link to="/signin" style={{textDecoration:"none"}}>
							<div class="card p-4 zoom card_back" style={{width: "100%",height:"100%",borderRadius:"12px",border:"1px solid #252936",color:"#fff"}}>
							<p style={{color:"#adadae",fontSize:"20px"}}>
							AI Standard
							</p>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
								$501 - 1000<span style={{fontSize:"15px",color:"#fff",fontWeight:""}}> /Per Subscription</span>
							</span>
							<span style={{color:"#adadae",marginTop:"1rem"}}>
								Daily 2% Return
							</span>
							</div>
							</Link>
						</div>
						<div className='col-md-3 mt-1'>
						<Link to="/signin" style={{textDecoration:"none"}}>
							<div class="card p-4 zoom card_back" style={{width: "100%",height:"100%",borderRadius:"12px",border:"1px solid #252936",color:"#fff"}}>
							<p style={{color:"#adadae",fontSize:"20px"}}>
							AI Plus
							</p>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
								$1001 - 5000<span style={{fontSize:"15px",fontWeight:"",color:"#fff"}}> /Per Subscription</span>
							</span>
							<span style={{color:"#adadae",marginTop:"1rem"}}>
								Daily 2% Return
							</span>
							</div>
							</Link>
						</div>
						<div className='col-md-3 mt-1'>
						<Link to="/signin" style={{textDecoration:"none"}}>
							<div class="card p-4 zoom card_back" style={{width: "100%",height:"100%",borderRadius:"12px",border:"1px solid #252936",color:"#fff"}}>
							<p style={{color:"#adadae",fontSize:"20px"}}>
							AI Pro
							</p>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
								$5001 - 10000<span style={{fontSize:"15px",fontWeight:"",color:"#fff"}}> /Per Subscription</span>
							</span>
							<span style={{color:"#adadae",marginTop:"1rem"}}>
								Daily 2% Return
							</span>
							</div>
							</Link>
						</div>
						<div className='col-md-3 mt-1'>
						<Link to="/signin" style={{textDecoration:"none"}}>
							<div class="card p-4 zoom card_back" style={{width: "100%",height:"100%",borderRadius:"12px",border:"1px solid #252936",color:"#fff"}}>
							<p style={{color:"#adadae",fontSize:"20px"}}>
							AI Prime
							</p>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
								$10001 - 100000<span style={{fontSize:"15px",fontWeight:"",color:"#fff"}}> /Per Subscription</span>
							</span>
							<span style={{color:"#adadae",marginTop:"1rem"}}>
								Daily 2% Return	
							</span>
							</div>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className='mt-5' ref={section2stakeRef}>
				<div class="card p-4" style={{width: "100%",height:"100%",borderRadius:"12px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
					<center>
					<span style={{fontSize:"30px",fontWeight:"800"}}>
						Stake Plans
					</span>
					<p style={{color:"#adadae",fontSize:"14px"}}>
					Explore various staking opportunities to diversify your investment portfolio, minimalize risk across different assets and projects
					</p>
					</center>
					<div className='row'>
						<div className='col-md-3 mt-1'>
							<Link to="/signin" style={{textDecoration:"none"}}>
							<div class="card p-4 zoom card_back" style={{width: "100%",height:"100%",borderRadius:"12px",border:"1px solid #252936",color:"#fff"}}>
							<p style={{color:"#adadae",fontSize:"20px"}}>
							2 Months Plan
							</p>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
								$500<span style={{fontSize:"15px",fontWeight:"",color:"#fff"}}> /2 Months Stake</span>
							</span>
							<span style={{color:"#adadae",marginTop:"1rem"}}>
								20% Return
							</span>
							</div>
							</Link>
						</div>
						<div className='col-md-3 mt-1'>
						<Link to="/signin" style={{textDecoration:"none"}}>
							<div class="card p-4 zoom card_back" style={{width: "100%",height:"100%",borderRadius:"12px",border:"1px solid #252936",color:"#fff"}}>
							<p style={{color:"#adadae",fontSize:"20px"}}>
							3 Months Plan
							</p>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
								$1000<span style={{fontSize:"15px",fontWeight:"",color:"#fff"}}> /3 Months Stake</span>
							</span>
							<span style={{color:"#adadae",marginTop:"1rem"}}>
							   40% Return
							</span>
							</div>
							</Link>
						</div>
						<div className='col-md-3 mt-1'>
						<Link to="/signin" style={{textDecoration:"none"}}>
							<div class="card p-4 zoom card_back" style={{width: "100%",height:"100%",borderRadius:"12px",border:"1px solid #252936",color:"#fff"}}>
							<p style={{color:"#adadae",fontSize:"20px"}}>
							4 Months Plan
							</p>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
								$5000<span style={{fontSize:"15px",fontWeight:"",color:"#fff"}}> /4 Months Stake</span>
							</span>
							<span style={{color:"#adadae",marginTop:"1rem"}}>
								60% Return
							</span>
							</div>
							</Link>
						</div>
						<div className='col-md-3 mt-1'>
						<Link to="/signin" style={{textDecoration:"none"}}>
							<div class="card p-4 zoom card_back" style={{width: "100%",height:"100%",borderRadius:"12px",border:"1px solid #252936",color:"#fff"}}>
							<p style={{color:"#adadae",fontSize:"20px"}}>
							5 Months Plan
							</p>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
								$10000<span style={{fontSize:"15px",fontWeight:"",color:"#fff"}}> /5 Months Stake</span>
							</span>
							<span style={{color:"#adadae",marginTop:"1rem"}}>
								 80% Return
							</span>
							</div>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className='mt-5' ref={section3ReferralRef}>
				<div class="card p-4" data-aos="flip-left" style={{width: "100%",height:"100%",borderRadius:"12px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
				<center>
				<p style={{color:"",fontSize:"18px",fontWeight:"800"}}>
				Unlock Exciting Rewards with Our Referral Program.
					</p>
					</center>
					<div className='row'>
						<div className='col-md-3 mt-1'>
						<p style={{fontWeight:"800",color:"#fcbc05"}}>
						Get Refer & Sign up to get 10% Bonus on Your First Deposit
							</p>
							<span style={{color:"#adadae"}}>
							Enjoy an exclusive 10% bonus on your first deposit, kickstart your journey with us on a high note.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
							<p style={{fontWeight:"800",color:"#fcbc05"}}>
							Refer a Friend and Earn 10% on Their First Deposit
							</p>
							<span style={{color:"#adadae"}}>
							When they sign up using your referral link and make their first deposit, you both receive a generous 10% bonus.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
						<p style={{fontWeight:"800",color:"#fcbc05"}}>
						Continuous Rewards with 5% Bonus from the Second Deposit Onwards
							</p>
							<span style={{color:"#adadae"}}>
							It doesn't stop at the first deposit For every subsequent deposit your referred users make, both you and your 
							friends receive a 5% bonus.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
						<p style={{fontWeight:"800",color:"#fcbc05"}}>
						Get 5% Bonus on Every Deposit Thereafter
							</p>
							<span style={{color:"#adadae"}}>
							Whether you joined through a referral link or not, we appreciate your commitment,Receive a 5% bonus on every 
							deposit you make from the first deposit onwards.
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className='mt-5'>
				<div class="card coming_soon p-4" data-aos="zoom-in-up" style={{width: "100%",height:"100%",backgroundColor:"#212531",borderRadius:"12px",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
				<img src={Cominsoon}/>
				</div>
			</div>

			<div className='mt-5'>
				<div class="card p-4" data-aos="fade-up"
    			 data-aos-duration="3000" style={{width: "100%",height:"100%",borderRadius:"12px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
					<div className='row'>
						<div className='col-md-3 mt-1'>
							<span style={{fontSize:"30px",fontWeight:"800",color:"#fcbc05"}}>
							Ideal platform for your deposits with ease
							</span>
						</div>
						<div className='col-md-3 mt-1'>
							<p style={{fontWeight:"800",color:"#fcbc05"}}>
							Referral Bonuses
							</p>
							<span style={{color:"#adadae"}}>
							Earn additional rewards by referring friends and family to our platform. Your success is our success.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
						<p style={{fontWeight:"800",color:"#fcbc05"}}>
							Secure Transactions
							</p>
							<span style={{color:"#adadae"}}>
							Our platform prioritizes the security of your assets. All transactions, including payouts, 
							are executed with the highest level of security measures.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
						<p style={{fontWeight:"800",color:"#fcbc05"}}>
							24/7 Support
							</p>
							<span style={{color:"#adadae"}}>
							We prioritize your experience and aim to provide unparalleled support whenever you need it. Our dedicated 
							support team is available 24/7 to assist you.
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-5">
			<Footer/>
			</div>
		</div> 
		</div>
		</>
	); 
} 
export default Landing;