import React, { useState } from 'react'; 
import '../../CSS/Signup.css'
import axios from 'axios'
import Logo from '../../Images/logo.png'
var CryptoJS = require("crypto-js");
const SigninForm = () => { 
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [msg, setMsg] = useState('');
      const [showPassword, setShowPassword] = useState(false);

      const signin = () =>{
            setMsg(<i class="fa fa-circle-o-notch fa-spin mt-2" style={{fontSize:'30px',color:"#fff"}}></i>)
              if(email.length>3 && password.length>0){
                   var encrypt1 = CryptoJS.AES.encrypt(email,"key")
                   var encrypt2 = CryptoJS.AES.encrypt(password,"key")
                   axios.post("https://backend.aitradeandstake.com/api/signin",{
                       encrypt1:encrypt1.toString(),
                       encrypt2:encrypt2.toString()
                   })
                   .then(async(res)=>{
                    if(res.data.token){
                        sessionStorage.setItem("token",res.data.token)
                        sessionStorage.setItem("email",res.data.email)
                        window.location.href="/dashboard"
                    }
                    else{
                        setMsg(res.data) 
                    }
                   })
                   .catch(err=>{
                    setMsg("User doesn't exist")
                  })
               }
               else{
                  setMsg("Please enter all details")
               }
         }
         const togglePasswordVisibility = () => {
            setShowPassword(!showPassword);
          };
	return ( 
        <div className='parent' style={{position:"relative",height:"100vh"}}>
             {/* <img src={Logo} style={{height:"10vh"}}/> */}
        <div className='child_class p-4' style={{backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderRadius:"6px"}}> 
        <div className='signup_Text'>
        {/* <span className=''>Hi!{"\n"} Welcome</span> */}
        <img src={Logo} style={{width:"90%"}}/>
        {/* <span className=''></span> */}
        </div>
        <br/>
        <div style={{textAlign:"left",color:"#fff"}}>
        <p>Enter your detail to login</p>
        </div>
            <div class="form__group field">
            <input type="input" class="form__field" placeholder="Name" onChange={(e)=>setEmail(e.target.value)} name="name" id='name' required />
            <label for="name" class="form__label">Email</label>
            </div>
            <div class="form__group field">
            <input type={showPassword?'text':'password'} class="form__field" placeholder="Name" onChange={(e)=>setPassword(e.target.value)} name="name" id='name' required />
            {showPassword?
              <i class="fa fa-eye" onClick={togglePasswordVisibility} style={{position:"absolute",top:"48%",right:"5px",color:"#fcbc0b",cursor:"pointer"}} aria-hidden="true"></i>
              :
              <i class="fa fa-eye-slash" onClick={togglePasswordVisibility} style={{position:"absolute",top:"48%",right:"5px",color:"#fcbc0b",cursor:"pointer"}} aria-hidden="true"></i>
            }
            <label for="name" class="form__label">Password</label>
            </div>
            <div class="form__group field" style={{textAlign:"right"}}>
            <a href="/forgotpassword" style={{textDecoration:"none"}}>
            <span for="name" class="" style={{color:"#fcbc05"}}>Forgot password?</span>
            </a>
            </div>
            <div class="form__group field">
            {/* <a href="/"> */}
            <button type="button" class="btn" onClick={signin} style={{minWidth:"50%",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Login</button>
            {/* <button type="button" class="btn btn-lg color_button" onClick={signin}>Signin</button> */}
            {/* </a> */}
            <br/>
            <p>{msg}</p>
            <br/>
            <p>Don't have an account?<a href="signup" style={{textDecoration:"none",color:"#fcbc05"}}><span style={{fontWeight:"800"}}> Signup</span></a></p>
            </div>
		</div> 
        </div>
	); 
} 
export default SigninForm;