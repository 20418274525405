import React, { useState } from 'react'; 
import Logo from '../../Images/logo.png'
import { Link } from 'react-router-dom'

const Navbar = ({ section1plansRef,section2stakeRef,section3ReferralRef }) => { 
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      };
	return ( 
		<div> 
		<nav class="navbar navbar-expand-lg " style={{backgroundColor:""}}>
  <div class="container-fluid">
    <a class="navbar-brand" href="/" style={{color:"#fff",fontWeight:"900"}}><img className='mob_logo' src={Logo} style={{width:"14vw"}}/></a>
    <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
    <li class="nav-item mt-2">
        <Link to="/signin">
        <button type="button" class="btn" style={{marginRight:"10px",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Login</button>
        </Link>
        </li>
        <li class="nav-item mt-2">
        <button onClick={() => scrollToSection(section1plansRef)} type="button" class="btn" style={{marginRight:"10px",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Plans</button>
        </li>
        <li class="nav-item mt-2">
        <button type="button" onClick={() => scrollToSection(section2stakeRef)} class="btn" style={{marginRight:"10px",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Stakes</button>
        </li>
        <li class="nav-item mt-2">
        <button type="button" onClick={() => scrollToSection(section3ReferralRef)} class="btn" style={{marginRight:"10px",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Referral</button>
        </li>
        <li class="nav-item mt-2">
        <Link to="/terms">
        <button type="button" class="btn" style={{marginRight:"10px",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Terms</button>
        </Link>
        </li>
        <li class="nav-item mt-2">
        <button type="button" class="btn" style={{marginRight:"10px",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>
        <i style={{fontSize:"20px"}} class="fa fa-telegram" aria-hidden="true"></i>
        </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
		</div> 
	); 
} 
export default Navbar;
