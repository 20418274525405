import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import axios from 'axios';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class StakeChartPie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        height: 208,
        animationEnabled: true,
        theme: "light2",
        backgroundColor: "transparent",
        title: {
          text: ""
        },
        data: [{
          type: "pie",
          startAngle: 75,
          showInLegend: true,
          legendText: "{label}: ${y}",
          indexLabelFontSize: 16,
          indexLabel: "${y}",
          dataPoints: []
        }],
        legend: {
          fontColor: "white" // Setting legend font color to white
        }
      }
    };
  }

  componentDidMount() {
    let token = sessionStorage.getItem("token");
    let email = sessionStorage.getItem("email");

    if (!token || !email) {
      window.location.href = "/";
    } else {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      axios.get("https://backend.aitradeandstake.com/api/validateToken", config)
        .then(res => {
          if (res.data === 'User validation failed') {
            sessionStorage.clear();
            window.location.href = '/';
          } else {
            axios.post("https://backend.aitradeandstake.com/api/get_my_staked_plans", { email: email }, config)
              .then(res => {
                const receivedData = res.data;

                var value = 0;
                var earnings = 0;

                for (let i = 0; i < res.data.length; i++) {
                  value += parseFloat(res.data[i].staked_investment_value);
                  earnings += parseFloat(res.data[i].staked_current_value) - parseFloat(res.data[i].staked_investment_value);
                }

                const dynamicDataPoints = [
                  {
                    label: "Investments", // Changed label from "Value" to "Investments"
                    y: value
                  },
                  {
                    label: "Earnings",
                    y: earnings
                  }
                ];

                const updatedOptions = {
                  ...this.state.options,
                  data: [{
                    ...this.state.options.data[0],
                    dataPoints: dynamicDataPoints
                  }]
                };

                this.setState({ options: updatedOptions });

              })
              .catch(error => {
                console.error("Error fetching data:", error);
              });
          }
        });
    }
  }

  render() {
    return (
      <div>
        <CanvasJSChart options={this.state.options} />
      </div>
    );
  }
}

export default StakeChartPie;
