import React, { useState } from 'react'; 

import Form from './SigninForm'
import '../../CSS/Style.css'
const Signin = () => { 
	return ( 
		<div className='first_sec_ran'>
		<div className='container'> 
        {/* <div className='logo' style={{textAlign:"right"}}> */}
       
        {/* </div> */}
        <Form/>
		</div> 
		</div> 
	); 
} 
export default Signin;