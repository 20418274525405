import React, { useState, useEffect } from 'react';
import Header from './Header';
import axios from 'axios';

const Kyc = () => {
  const [btn_loader,setbtn_loader] = useState(false)
  const [formData, setFormData] = useState({
    fullName: '',
    dob: '',
    mobile: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    documentType: '',
    docFrontImg: null,
    docBackImg: null,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [kyc_details_state, setkyc_details_state] = useState('Loading');

  useEffect(() => {
    async function initial(){
    let token = sessionStorage.getItem("token");
    let email = sessionStorage.getItem("email");

    if (!token || !email) {
      window.location.href = "/";
    } else {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      axios.get("https://backend.aitradeandstake.com/api/validateToken", config)
        .then(res => {
          if (res.data === 'User validation failed') {
            sessionStorage.clear();
            window.location.href = '/';
          }
        });
        const kyc_detail = await axios.post("https://backend.aitradeandstake.com/api/getKycDetails",{email:email}, config)
        setkyc_details_state(kyc_detail.data.kycDetails.KycStatus)
    }
  }
  initial()
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setbtn_loader(true)
    const { fullName, dob, mobile, address, postalCode, city, country, documentType, docFrontImg, docBackImg } = formData;

    if (!fullName || !dob || !mobile || !address || !postalCode || !city || !country || !documentType || !docFrontImg || !docBackImg) {
      setErrorMessage('All fields are required.');
      return;
    }

    try {
      const token = sessionStorage.getItem("token");
      const email = sessionStorage.getItem("email");

      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };

      const formDataToSend = new FormData();
      formDataToSend.append('email', email);
      formDataToSend.append('fullName', fullName);
      formDataToSend.append('dob', dob);
      formDataToSend.append('mobile', mobile);
      formDataToSend.append('address', address);
      formDataToSend.append('postalCode', postalCode);
      formDataToSend.append('city', city);
      formDataToSend.append('country', country);
      formDataToSend.append('documentType', documentType);
      formDataToSend.append('docFrontImg', docFrontImg);
      formDataToSend.append('docBackImg', docBackImg);

      const response = await axios.post("https://backend.aitradeandstake.com/api/kycRequest", formDataToSend,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          'Content-Type': 'multipart/form-data',
        },
      }
      );

      if (response.data.success) {
        setSuccessMessage('KYC request submitted successfully.');
        setbtn_loader(false)
        setTimeout(()=>{
          window.location.reload();
        },3000)
      } else {
        setErrorMessage(response.data.message || 'Failed to submit KYC request.');
        setbtn_loader(false)
        setTimeout(()=>{
          window.location.reload();
        },3000)
      }
    } catch (err) {
      setErrorMessage('An error occurred while submitting your KYC request. Please try again.');
      setbtn_loader(false)
      setTimeout(()=>{
        window.location.reload();
      },3000)
    }
  };

  return (
    <>
      <Header />
      <div className="container py-5" style={{ backgroundColor: "#17161b", height: "auto"}}>
        {kyc_details_state==='Loading'?
        <div style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}> 
        <i class="fa fa-circle-o-notch fa-spin mt-2" style={{fontSize:'30px',color:"#fff"}}></i>
        </div>
          :
          <>
          {kyc_details_state==='unVerified'?
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 px-2">
              <div className="card py-4" style={{ backgroundColor: "#212531", borderRadius: "6px",border:"1px solid #252936" }}>
                <div className="mb-4">
                  <p className="text-center mt-3 text-white" style={{ fontWeight: 'bold', fontSize: 25 }}>Basic Information</p>
                  <p className='text-white-50 text-center'>Enter your details as they appear on your identification document.</p>
                </div>
  
                <form className='mx-5' onSubmit={handleSubmit}>
                  {/* Form fields */}
                  <div className="row" style={{textAlign:"left"}}>
                    <div className="col-md-6 mb-3">
                      <label className="form-label text-white">Full Name</label>
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your full name"
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label text-white">Date of Birth</label>
                      <input
                        type="date"
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        className="form-control"
                        style={{  border: "none" }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label text-white">Mobile Number</label>
                      <input
                        type="tel"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your mobile number"
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label text-white">Address</label>
                      <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your address"
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label text-white">Postal Code</label>
                      <input
                        type="text"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your postal code"
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label text-white">City</label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your city"
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label text-white">Country</label>
                      <input
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Enter your country"
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label text-white">Document Type</label>
                      <select
                        name="documentType"
                        value={formData.documentType}
                        onChange={handleChange}
                        className="form-control"
                        style={{ border: "none" }}
                      >
                        <option value="">Select document type</option>
                        <option value="Passport">Passport</option>
                        <option value="Driver's License">Driver's License</option>
                        <option value="National ID">National ID</option>
                      </select>
                    </div>
                  </div>
  
          {/* File Upload Section */}
<div className="row mt-5">
  <div className="col-md-6 mb-3 text-center">
    <div
      className="p-4 d-flex flex-column align-items-center"
      style={{ backgroundColor: "transparent", border: '2px solid #fff', borderRadius: "12px", borderStyle: 'dashed' }}
    >
      <i className="bi bi-cloud-arrow-up" style={{ fontSize: "2rem", color: "#FCBC05" }}></i>
      <p className="text-white" style={{ fontWeight: 'bold', marginTop: '2rem', fontSize: '1.2rem' }}>Front side of your document</p>
      <p className="text-white-50" style={{ fontSize: '1.1rem' }}>Upload the front side of your document <br /> <small className="text-white-50">Support: PNG, JPG, JPEG</small></p>

      <button
        className="btn mt-3"
        type='button'
        style={{
          border: "2px solid #FCBC05",
          borderRadius: '2rem',
          color: "#FCBC05",
          width: '100%',
          height: '35px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        <label style={{ cursor: "pointer", top: 3, inset: 0 }}>
          <input
            type="file"
            name="docFrontImg"
            onChange={handleFileChange}
            style={{
              position: 'absolute',
              width: 0,
              height: 0,
              opacity: 0,
              pointerEvents: 'none',
            }}
            accept=".png,.jpg,.jpeg"
          />
          Choose File
        </label>
      </button>

      {formData.docFrontImg && (
        <img
          src={URL.createObjectURL(formData.docFrontImg)}
          alt="Front side preview"
          style={{
            marginTop: '10px',
            maxWidth: '100%',
            maxHeight: '150px',
            objectFit: 'cover',
            borderRadius: '6px',
            border: '2px solid #FCBC05',
            padding:'10px'
          }}
        />
      )}
    </div>
  </div>

  <div className="col-md-6 mb-3 text-center">
    <div
      className="p-4 d-flex flex-column align-items-center"
      style={{ backgroundColor: "transparent", border: '2px solid #fff', borderRadius: "12px", borderStyle: 'dashed' }}
    >
      <i className="bi bi-cloud-arrow-up" style={{ fontSize: "2rem", color: "#FCBC05" }}></i>
      <p className="text-white" style={{ fontWeight: 'bold', marginTop: '2rem', fontSize: '1.2rem' }}>Back side of your document</p>
      <p className="text-white-50" style={{ fontSize: '1.1rem' }}>Upload the back side of your document <br /> <small className="text-white-50">Support: PNG, JPG, JPEG</small></p>

      <button
        className="btn mt-3"
        type='button'
        style={{
          border: "2px solid #FCBC05",
          borderRadius: '2rem',
          color: "#FCBC05",
          width: '100%',
          height: '35px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        <label style={{ cursor: "pointer", top: 3, inset: 0 }}>
          <input
            type="file"
            name="docBackImg"
            onChange={handleFileChange}
            style={{
              position: 'absolute',
              width: 0,
              height: 0,
              opacity: 0,
              pointerEvents: 'none',
            }}
            accept=".png,.jpg,.jpeg"
          />
          Choose File
        </label>
      </button>

      {formData.docBackImg && (
        <img
          src={URL.createObjectURL(formData.docBackImg)}
          alt="Back side preview"
          style={{
            marginTop: '10px',
            maxWidth: '100%',
            maxHeight: '150px',
            objectFit: 'cover',
            borderRadius: '6px',
            border: '2px solid #FCBC05',
            padding:'10px'
          }}
        />
      )}
    </div>
  </div>
</div>

{/* Error & Success Messages */}
{errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
{successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}

{/* Continue Button */}
<div className="d-flex flex-column justify-content-start align-items-start mt-4">
  <button className="btn py-2 mt-3" style={{ backgroundColor: "#FCBC05", color: "#000", width: '100%' }}>
    {btn_loader?<i class="fa fa-circle-o-notch fa-spin mt-2" style={{fontSize:'30px',color:"#fff"}}></i>:'Submit'}
  </button>
</div>

                </form>
              </div>
            </div>
          </div>
          :
          <div style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}> 
           <center>
           {kyc_details_state==='Pending'?
            <i class="fa fa-exclamation-circle" style={{fontSize:"100px",color:"orange"}} aria-hidden="true"></i>
            :
            ''               
          }
           {kyc_details_state==='Verified'?
            <i class="fa fa-check-circle" style={{fontSize:"100px",color:"green"}} aria-hidden="true"></i>
            :
            ''               
          }
            {kyc_details_state==='Rejected'?
            <i class="fa fa-times-circle" style={{fontSize:"100px",color:"red"}} aria-hidden="true"></i>
            :
            ''               
          }
           <br/><br/>
             <h5 class="card-title" style={{fontWeight:"700",color:"#fff"}}> Your current KYC status : {kyc_details_state}</h5>
           </center>
          </div>
          }
          </>
        }
      </div>
    </>
  );
}

export default Kyc;
