import React, { useState,useEffect } from 'react'; 
import Header from './Header'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import copy from "copy-to-clipboard";
import BSC from "../Images/binance.png"
import { Link } from 'react-router-dom';



const MyPayoutDashboard = () => { 
    const [balance, setBalance] = useState(0);

    const [refid, setRefid] = useState('');

    const [silver_payout, setsilver_payout] = useState([]);
    const [gold_payout, setgold_payout] = useState([]);
    const [diamond_payout, setdiamond_payout] = useState([]);
    const [platinum_payout, setplatinum_payout] = useState([]);
    const [fifth_payout, setfifth_payout] = useState([]);

    const [silver_payout_sum, setsilver_payout_sum] = useState(0);
    const [gold_payout_sum, setgold_payout_sum] = useState(0);
    const [diamond_payout_sum, setdiamond_payout_sum] = useState(0);
    const [platinum_payout_sum, setplatinum_payout_sum] = useState(0);
    const [fifth_payout_sum, setfifth_payout_sum] = useState(0);

    useEffect(() => {
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
              if(!token || !email){
                  window.location.href="/"
              }
              else{
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                .then(res=>{
                  if(res.data==='User validation failed'){
                    sessionStorage.clear();
                    window.location.href='/'
                  }
                  else{
                    setRefid(res.data.data._id)
                    axios.post("https://backend.aitradeandstake.com/api/get_user_balance",{email:email},config)
                    .then(data=>{
                        setBalance(parseFloat(data.data))
                    })
                    firstPlan();
                    secondPlan();
                    thirdPlan();
                    fourthPlan();
                    fifthPlan();
                  }
                })
              }
  },[]);

  const fifthPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_fifth_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setfifth_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_fifth_plan_payout_sum",{email:email},config)
    .then(res=>{
        setfifth_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }

  const fourthPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_fourth_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setplatinum_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_fourth_plan_payout_sum",{email:email},config)
    .then(res=>{
        setplatinum_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }

  const thirdPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_third_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setdiamond_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_third_plan_payout_sum",{email:email},config)
    .then(res=>{
        setdiamond_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }
  
  const secondPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_second_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setgold_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_second_plan_payout_sum",{email:email},config)
    .then(res=>{
        setgold_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }
  
  const firstPlan = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_first_plan_payout",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        setsilver_payout(data)
    })
    axios.post("https://backend.aitradeandstake.com/api/get_first_plan_payout_sum",{email:email},config)
    .then(res=>{
        setsilver_payout_sum(parseFloat(res.data).toFixed(2))
    })
  }


 
	return ( 
        <>
		<div className='container my-3'> 

        <div class="card mt-3" style={{width: "100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body">

            <div className='row mt-3'>
            <div className='col-md-3 col-sm-12 mt-1'>
                <Link to="/payout" style={{textDecoration:"none"}}>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Lite Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {fifth_payout_sum}
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Lite Payout</p>
                        </div>
                        </Link>
                    </div>

                    <div className='col-md-3 col-sm-12 mt-1'>
                    <Link to="/payout" style={{textDecoration:"none"}}>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Standard Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {silver_payout_sum}
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Standard Payout</p>
                        </div>
                        </Link>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-1'>
                    <Link to="/payout" style={{textDecoration:"none"}}>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Plus Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {gold_payout_sum}  
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Plus Payout</p>
                        </div>
                        </Link>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-1'>
                    <Link to="/payout" style={{textDecoration:"none"}}>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Pro Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {diamond_payout_sum}   
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Pro Payout</p>
                        </div>
                        </Link>
                    </div>
                </div>

                <div className='row mt-3'>
                <div className='col-md-3 col-sm-12 mt-1'>
                <Link to="/payout" style={{textDecoration:"none"}}>
                        <div class="card p-3 zoom" style={{textAlign:"left",width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid green"}}>
                            <p class="card-text" style={{color:"#adadae"}}>AI Prime Payout</p>
                            <span class="" style={{fontWeight:"900",fontSize:"23px",color:"#fcbc0b"}}>$ <span style={{color:"#fff"}}>
                            {platinum_payout_sum}   
                            </span></span>
                            <p class="card-text mt-4" style={{color:"#adadae"}}>All AI Prime Payout</p>
                        </div>
                        </Link>
                    </div>
                    </div>

     
		</div> 
        </div>
		</div> 
        </>
	); 
} 
export default MyPayoutDashboard;