import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import axios from 'axios';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class StakeChartPie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        height: 208,
        animationEnabled: true,
        theme: "light2",
        backgroundColor: "transparent",
        title: {
          text: ""
        },
        data: [{
          type: "pie",
          startAngle: 75,
          showInLegend: true,
          legendText: "{label}: ${y}",
          indexLabelFontSize: 16,
          indexLabel: "${y}",
          dataPoints: []
        }],
        legend: {
          fontColor: "white" // Setting legend font color to white
        }
      }
    };
  }

  componentDidMount() {
    let token = sessionStorage.getItem("token");
    let email = sessionStorage.getItem("email");
  
    if (!token || !email) {
      window.location.href = "/";
    } else {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
  
      axios.get("https://backend.aitradeandstake.com/api/validateToken", config)
        .then(async (res) => {
          if (res.data === 'User validation failed') {
            sessionStorage.clear();
            window.location.href = '/';
          } else {
            const fifth_paln_sum = await axios.post("https://backend.aitradeandstake.com/api/get_fifth_plan_payout_sum", { email: email }, config);
            const fourth_paln_sum = await axios.post("https://backend.aitradeandstake.com/api/get_fourth_plan_payout_sum", { email: email }, config);
            const third_paln_sum = await axios.post("https://backend.aitradeandstake.com/api/get_third_plan_payout_sum", { email: email }, config);
            const second_paln_sum = await axios.post("https://backend.aitradeandstake.com/api/get_second_plan_payout_sum", { email: email }, config);
            const first_paln_sum = await axios.post("https://backend.aitradeandstake.com/api/get_first_plan_payout_sum", { email: email }, config);
  
            const purchased_plan_value = await axios.post("https://backend.aitradeandstake.com/api/get_my_all_plans", { email: email }, config);
            
            let value = 0;
            for (let i = 0; i < purchased_plan_value.data.length; i++) {
              value += parseFloat(purchased_plan_value.data[i].plan_amount);
            }
  
            const dynamicDataPoints = [
              {
                label: "Investments",
                y: value
              },
              {
                label: "Earnings",
                y: (parseFloat(fifth_paln_sum.data) + parseFloat(fourth_paln_sum.data) + parseFloat(third_paln_sum.data) +
                  parseFloat(second_paln_sum.data) + parseFloat(first_paln_sum.data)).toFixed(2)
              }
            ];
  
            const updatedOptions = {
              ...this.state.options,
              data: [{
                ...this.state.options.data[0],
                dataPoints: dynamicDataPoints
              }]
            };
  
            // Set state after processing the data
            this.setState({ options: updatedOptions });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle error if necessary
        });
    }
  }
  

  render() {
    return (
      <div>
        <CanvasJSChart options={this.state.options} />
      </div>
    );
  }
}

export default StakeChartPie;
