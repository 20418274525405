import React, { useState } from 'react'; 
import '../../CSS/Signup.css';
import axios from 'axios';
import Logo from '../../Images/logo.png';

const ForgotPasswordForm = () => { 
    const [email, setEmail] = useState('');
    const [otpSent, setOtpSent] = useState(false); // To track if OTP was sent
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false); // To show a loading spinner
    const [showPassword, setShowPassword] = useState(false); // To toggle password visibility

    // Handler for sending OTP to email
    const handleSendOtp = async () => {
        setLoading(true); // Start loading
        setError(''); // Clear previous error messages
        try {
            const response = await axios.post('https://backend.aitradeandstake.com/api/forgotPassword', { email });
            setOtpSent(true); // Move to the OTP entry stage
            setMessage(response.data.message); // Display the success message
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to send OTP. Please try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    // Handler for resetting the password using OTP
    const handleResetPassword = async () => {
        setLoading(true); // Start loading
        setError(''); // Clear previous error messages
        try {
            const response = await axios.post('https://backend.aitradeandstake.com/api/resetPassword', { email, otp, newPassword });
            setMessage('Password reset successfully! You can now log in.');
            setOtpSent(false); // Reset the state to initial form
            setEmail(''); // Clear input fields
            setOtp('');
            setNewPassword('');
            setTimeout(()=>{
                  window.location.href="/signin"
            });
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to reset password. Please check the OTP and try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return ( 
        <div className='parent' style={{position:"relative", height:"100vh"}}>
            <div className='child_class p-4' style={{backgroundColor:"#212531", border:"1px solid #252936", color:"#fff", borderRadius:"6px"}}> 
                <div className='signup_Text'>
                    <img src={Logo} style={{width:"90%"}} alt="Logo" />
                </div>
                <br />
                {message && <p style={{color: 'green'}}>{message}</p>}
                {error && <p style={{color: 'red'}}>{error}</p>}
                {!otpSent ? (
                    <>
                        <div style={{textAlign:"left", color:"#fff"}}>
                            <p>Enter your email to reset your password</p>
                        </div>
                        <div className="form__group field">
                            <input
                                type="email"
                                className="form__field"
                                placeholder="Email"
                                name="email"
                                id='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <label htmlFor="email" className="form__label">Email</label>
                        </div>
                        <div className="form__group field">
                            <button 
                                type="button" 
                                className="btn" 
                                style={{minWidth:"50%", border:"1px solid #fcbc0b", backgroundColor:"#212531", color:"#fcbc0b"}} 
                                onClick={handleSendOtp}
                                disabled={loading} // Disable button while loading
                            >
                                {loading ? <i className="fa fa-circle-o-notch fa-spin"></i> : 'Submit'}
                            </button>
                            <br />
                            <br />
                            <p>Don't have an account?<a href="signup" style={{textDecoration:"none", color:"#fcbc05"}}><span style={{fontWeight:"800"}}> Signup</span></a></p>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{textAlign:"left", color:"#fff"}}>
                            <p>Enter the OTP sent to your email and your new password</p>
                        </div>
                        <div className="form__group field">
                            <input
                                type="text"
                                className="form__field"
                                placeholder="OTP"
                                name="otp"
                                id="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                            />
                            <label htmlFor="otp" className="form__label">OTP</label>
                        </div>
                        <div className="form__group field">
                            <input
                                type={showPassword ? "text" : "password"} // Toggle input type based on visibility state
                                className="form__field"
                                placeholder="New Password"
                                name="newPassword"
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            <label htmlFor="newPassword" className="form__label">New Password</label>
                            <span 
                                onClick={() => setShowPassword(!showPassword)} // Toggle show/hide password
                                style={{position: 'absolute', right: '15px', top: '13px', cursor: 'pointer', color: '#fcbc0b'}}
                            >
                                {showPassword ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                            </span>
                        </div>
                        <div className="form__group field">
                            <button 
                                type="button" 
                                className="btn" 
                                style={{minWidth:"50%", border:"1px solid #fcbc0b", backgroundColor:"#212531", color:"#fcbc0b"}} 
                                onClick={handleResetPassword}
                                disabled={loading} // Disable button while loading
                            >
                                {loading ? <i className="fa fa-circle-o-notch fa-spin"></i> : 'Reset Password'}
                            </button>
                        </div>
                    </>
                )}
            </div> 
        </div>
    );
}; 

export default ForgotPasswordForm;
