import React, { useState,useEffect } from 'react'; 
import Modal from 'react-bootstrap/Modal';
import Header from './Header'
import Chart from './StakeChartPie'
import axios from 'axios'
import Stake from './Stake'
const MyStakes = () => { 
    // const [show, setShow] = useState(false);
    const [msg, setMsg] = useState('');
    // const [selectedplans, setselectedplans] = useState(null);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [plans, setPlans] = useState([]);
    const [total_staked_value, settotal_staked_value] = useState(0);
    const [total_earnings, settotal_earnings] = useState(0);

    useEffect(() => {
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
              if(!token || !email){
                  window.location.href="/"
              }
              else{
                setToken(token)
                setEmail(email)
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                .then(res=>{
                  if(res.data==='User validation failed'){
                    sessionStorage.clear();
                    window.location.href='/'
                  }
                  else{
                    axios.post("https://backend.aitradeandstake.com/api/get_my_staked_plans",{
                        email:email
                    },config)
                    .then(data=>{
                        setPlans(data.data)
                        var value = 0;
                        var earnings = 0;
                        for(let i=0;i<data.data.length;i++){
                            if(data.data.length>0){
                                value = value+parseFloat(data.data[i].staked_investment_value)
                                earnings = earnings+(parseFloat(data.data[i].staked_current_value)-parseFloat(data.data[i].staked_investment_value))
                            }
                            if(data.data.length-1==i){
                                settotal_staked_value(value)
                                settotal_earnings(earnings)
                            }
                        }
                    })
                  }
                })
              }
  },[]);

    // const handleClose = () => setShow(false);
    const handleShow = (data) => {
        // setselectedplans(data)
        // setShow(true);
        window.location.href="/payout"
    }
	return ( 
        <>
         <Header/>
		<div className='container my-3'> 
       <div class="card" style={{width: "100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body">
            <h5 class="card-title mt-3" style={{fontWeight:"700",textAlign:"left"}}>Staking</h5>
            <p style={{color:"#adadae",textAlign:"left"}}>
            Secure your financial future by engaging in strategic staking opportunities tailored to optimize your wealth potential
            </p>
            <div className='row'>
                <div className='col-md-3'>
                <span>Overview</span>
                    <div class="card py-2 mt-1" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                       <span style={{color:"#adadae"}}>Total Staked Value</span>
                       <span style={{color:"#fcbc0b",fontSize:"20px",fontWeight:"700"}}>$ {total_staked_value}</span>
                    </div>
                    <div class="card py-2 mt-1" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                       <span style={{color:"#adadae"}}>Total Earnings</span>
                       <span style={{color:"#fcbc0b",fontSize:"20px",fontWeight:"700"}}>$ {total_earnings.toFixed(2)}</span>
                    </div>
                    <div class="card py-2 mt-1" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                       <span style={{color:"#adadae"}}>Total Staking Plans</span>
                       <span style={{color:"#fcbc0b",fontSize:"20px",fontWeight:"700"}}>{plans.length}</span>
                    </div>
                </div>   
                <div className='col-md-9' style={{height:"100%"}}>
                    <span>Stake Overview Chart</span>
                    <div class="card py-2 mt-1" style={{width: "100%",height:"100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                    <Chart/>
                    </div>
                </div>   
            </div>
            
            <Stake/>

                <div className='row mt-3'>
                <h5 class="card-title" style={{fontWeight:"700"}}>My Stakes</h5>
                    {plans.map(index=>{
                        const objectId = index._id;
                        const timestamp1 = parseInt(objectId.substring(0, 8), 16) * 1000;
                        const dateFromObjectId = new Date(timestamp1);

                        let timestamp = timestamp1;
                        let dateObject = new Date(timestamp);
                        dateObject.setDate(dateObject.getDate() + parseFloat(index.stake_duration)*30);
                        return(
                            <>
                            <div className='col-md-4 col-sm-12 mt-1'>
                            <div class="card py-2" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                                <div className='flex-container to_hide_somewhere' style={{textAlign:"left"}}>
                                    <div className='some m-2'>

                                        <p class="card-text" style={{color:"#adadae"}}>Stake ID : <span style={{color:"#fcbc0b"}}>{index._id.slice(0,5)+'..'+index._id.slice(19,24)}</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Invested amt : <span style={{color:"#fcbc0b"}}>$ {index.staked_investment_value}</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>APR : <span style={{color:"#fcbc0b"}}>{index.stake_interest}%</span></p>
                                        <p class="card-text" style={{color:"#adadae"}}>Plan status : {index.status==='Initiated'?<span style={{color:"green"}}>Active</span>:<span style={{color:"green"}}>Completed</span>}</p>

                                    </div> 
                                    <div className='some m-2'>
                                    <p class="card-text" style={{color:"#adadae"}}>Start time : <span style={{color:"#fcbc0b"}}>{dateFromObjectId.toLocaleDateString()}</span></p>
                                    <p class="card-text" style={{color:"#adadae"}}>Maturity date : <span style={{color:"#fcbc0b"}}>{dateObject.toLocaleDateString()}</span></p>
                                    <p class="card-text" style={{color:"#adadae"}}>Earning amount : <span style={{color:"#fcbc0b"}}>$ {parseFloat(index.staked_current_value) - parseFloat(index.staked_investment_value)}</span></p>
                                    </div> 
                                    </div>    

                                    <div className='flex-container to_hide_somewhere__' style={{textAlign:"left"}}>
                                    <div className='some m-2'>
                                        <p class="card-text" style={{color:"#adadae"}}>Stake ID </p>
                                        <p class="card-text" style={{color:"#adadae"}}>Invested amt </p>
                                        <p class="card-text" style={{color:"#adadae"}}>Return </p>
                                        <p class="card-text" style={{color:"#adadae"}}>Start time </p>
                                        <p class="card-text" style={{color:"#adadae"}}>Maturity date </p>
                                        <p class="card-text" style={{color:"#adadae"}}>Earning amount </p>
                                        <p class="card-text" style={{color:"#adadae"}}>Plan status </p>
                                    </div> 
                                    <div className='some m-2' style={{textAlign:"right"}}>
                                        <p style={{color:"#fcbc0b"}}>{index._id.slice(0,5)+'..'+index._id.slice(19,24)}</p>
                                        <p style={{color:"#fcbc0b"}}>$ {index.staked_investment_value}</p>
                                        <p style={{color:"#fcbc0b"}}>{index.stake_interest}%</p>
                                        <p style={{color:"#fcbc0b"}}>{dateFromObjectId.toLocaleDateString()}</p>
                                        <p style={{color:"#fcbc0b"}}>{dateObject.toLocaleDateString()}</p>
                                        <p style={{color:"#fcbc0b"}}>$ {parseFloat(index.staked_current_value) - parseFloat(index.staked_investment_value)}</p>
                                        {index.status==='Initiated'?<p style={{color:"green"}}>Active</p>:<p style={{color:"green"}}>Completed</p>}
                                    </div> 
                                    </div>  
                                     
                                    {/* <center className='my-3'>
                                        <button type="button" class="btn salon_user_button" onClick={()=>handleShow(index)}>Check Payout</button>
                                        </center> */}
                            </div>
                        </div>
                        </>
                        )
                    })}
                </div>

                <div class="card py-2 mt-3" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderTop:"1px solid #fcbc05"}}>
            <div className='row'>
                <div className='col-md-4 px-3' style={{borderRight:"3px solid #252936"}}>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}> Earn Passive Income</p>
                <p className='' style={{color:"#adadae"}}>Staking with us opens up a world of opportunities to earn passive income effortlessly</p>
                </div>   
                <div className='col-md-4 px-3' style={{borderRight:"3px solid #252936"}}>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}> Security and Reliabilitye</p>
                <p className='' style={{color:"#adadae"}}>Emphasize the security measures in place and the reliability of the platform</p>
                </div>   
                <div className='col-md-4 px-3'>
                <p class="card-title mt-3" style={{fontWeight:"700",color:"#fcbc0b"}}> Community Engagement</p>
                <p style={{color:"#adadae"}}> Showcase how staking contributes to the overall health and growth of the community</p>
                </div>  
            </div>
            </div>
            </div>
            </div>
		</div> 
        </>
	); 
} 
export default MyStakes;