import React, { useState,useEffect } from 'react'; 
import Modal from 'react-bootstrap/Modal';
import axios from 'axios'
const Stake = () => { 
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState('');
    const [selectedplans, setselectedplans] = useState(null);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [amount, setAmount] = useState('');
    const [calculatedapr, setcalculatedapr] = useState(0);
    const [disableBtn, setdisableBtn] = useState(false);
    const [plans, setPlans] = useState([
        {
            name:'2 Months Plan',
            amount:500,
            locking_period:2,
            interest:'20'
        },
        {
            name:'3 Months Plan',
            amount:1000,
            locking_period:3,
            interest:'40'
        },
        {
            name:'4 Months Plan',
            amount:5000,
            locking_period:4,
            interest:'60'
        },
        {
            name:'5 Months Plan',
            amount:10000,
            locking_period:5,
            interest:'80'
        }
    ]);

    const calculateValue = (data) =>{
        if(data.length>0){
            setAmount(data)
            // console.log((((100*40)/100)/12)*3)

            

            var calculateVal = ((parseFloat(data)*(parseFloat(selectedplans.interest)/100))).toFixed(2)
            setcalculatedapr(calculateVal)
            if(parseFloat(selectedplans.locking_period)==2){
                if(parseFloat(data)>=500 && parseFloat(data)<1000){
                    setdisableBtn(true)
                    setMsg('')
                }
                else{
                    setMsg("Please enter amount between $500 to $1,000")
                    setdisableBtn(false)
                }
            }
            else if(parseFloat(selectedplans.locking_period)==3){
                if(parseFloat(data)>=1000 && parseFloat(data)<5000){
                    setdisableBtn(true)
                    setMsg('')
                }
                else{
                    setMsg("Please enter amount between $1,000 to $5,000")
                    setdisableBtn(false)
                }
            }
            else if(parseFloat(selectedplans.locking_period)==4){
                if(parseFloat(data)>=5000 && parseFloat(data)<10000){
                    setdisableBtn(true)
                    setMsg('')
                }
                else{
                    setMsg("Please enter amount between $5,000 to $10,000")
                    setdisableBtn(false)
                }
            }
            else if(parseFloat(selectedplans.locking_period)==5){
                if(parseFloat(data)>=10000){
                    setdisableBtn(true)
                    setMsg('')
                }
                else{
                    setMsg("Minimum investment amount is $10,000")
                    setdisableBtn(false)
                }
            }
        }
        else{
            setcalculatedapr(0)
            setdisableBtn(false)
            setMsg('')
        }
       
    }

    useEffect(() => {
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
              if(!token || !email){
                  window.location.href="/"
              }
              else{
                setToken(token)
                setEmail(email)
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                .then(res=>{
                  if(res.data==='User validation failed'){
                    sessionStorage.clear();
                    window.location.href='/'
                  }
                })
              }
  },[]);

    const handleClose = () =>{
        setcalculatedapr(0)
        setShow(false);
        setMsg("")
    }
    const handleShow = (data) => {
        setselectedplans(data)
        setShow(true);
    }
    const purchasePlan = () =>{
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
        setMsg(<i class="fa fa-circle-o-notch fa-spin mt-2" style={{fontSize:'30px'}}></i>)
        if(selectedplans!=null){
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.post("https://backend.aitradeandstake.com/api/stake_req",{
                email:email,
                staked_investment_value:amount,
            },config)
            .then(res=>{
                setMsg(res.data)
            })
        }
        else{
            setMsg("No plans selected")
        }
    }
	return ( 
		<div className='my-3'> 
       <div class="" style={{width: "100%",borderRadius:"6px",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body">
            <h5 class="card-title" style={{fontWeight:"700"}}>Staking Plans</h5>
                <div className='row'>
                    {plans.map(index=>{
                        return(
                            <div className='col-md-3 col-sm-12 mt-1'>
                            <div class="card py-2 zoom" style={{width: "100%",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
                                <span class="card-text"  style={{color:"#adadae",fontSize:"20px"}}>{index.name}</span>
                                <hr/>
                                <span class="card-text" style={{fontWeight:"900",fontSize:"25px",color:"#fcbc0b"}}><span style={{fontSize:"18px",color:"#fff"}}>$</span> {index.amount} 
                                <span style={{fontSize:"12px",color:"#adadae"}}>/ {index.locking_period} Months</span></span>
                                <span class="card-text" style={{color:"#adadae"}}>{index.interest}% Return</span><br/>
                                <span class="card-text" style={{fontWeight:"",fontSize:"13px",color:"#adadae"}}>Locking Period : {index.locking_period} Months</span>
                                <span class="card-text" style={{fontWeight:"",fontSize:"13px",color:"#adadae"}}>Capital Return : End of Term</span>
                                <br/>
                                <center>
                                <button type="button" class="btn salon_user_button" onClick={()=>handleShow(index)}>Invest Now!</button>
                                </center>
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>
            </div>
            <Modal 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered      
            show={show} onHide={handleClose}>
            <Modal.Header>
            <Modal.Title>Stake Now </Modal.Title>
            <i class="fa fa-times" style={{cursor:"pointer"}} onClick={handleClose} aria-hidden="true"></i>
            </Modal.Header>
            <Modal.Body>
            <div className='row'>
                <div className='col-md-6 mt-1'>
                <div class="card p-2" style={{width: "100%",backgroundColor:"#212531",height:"100%",color:"#fff"}}>
                <p class="card-text" style={{fontWeight:"900",fontSize:""}}>Stake Calculator</p>
                <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Stake amount</label>
                <input type="number" class="form-control" onChange={(e)=>calculateValue(e.target.value)} placeholder="Enter amount"/>
                <small style={{float:"right"}} class="card-text">Didn't have fund? <a href="/wallet" style={{textDecoration:"none",color:"#fcbc05"}}>Add now </a></small>
                </div>
                <div className='card p-2'>
                <span style={{fontWeight:"900"}}>
                    Your calculated earning : $ {calculatedapr}
                </span>
                </div>
                </div>
                </div>
                <div className='col-md-6 mt-1'>
                <div class="card p-2" style={{width: "100%",backgroundColor:"#212531",color:"#fff"}}>
                <p class="card-text" style={{fontWeight:"900",fontSize:""}}>Plan details</p>
                <span class="card-text" style={{fontWeight:"",color:"#adadae"}}>Plan Name : <span style={{color:"#fcbc0b"}}>{selectedplans?selectedplans.name:''}</span></span>
                <span class="card-text" style={{color:"#adadae"}}>Return: <span style={{color:"#fcbc0b"}}>{selectedplans?selectedplans.interest:''}%</span></span> 
                <span class="card-text" style={{color:"#adadae"}}>Locking Period: <span style={{color:"#fcbc0b"}}>{selectedplans?selectedplans.locking_period:''} Months</span></span><br/>    
                   
                <span class="card-text" style={{color:"#adadae"}}>Minimum amount to stake: <span style={{color:"#fcbc0b"}}>$ {selectedplans?selectedplans.amount:0}</span></span>
                <span class="card-text" style={{color:"#adadae"}}>Platform fees: <span style={{color:"#fcbc0b"}}>$ 0.00</span></span><br/>            
                </div> 
                </div>
                <div className='mt-4 text-center'>
                {disableBtn?
                <button type="button" class="btn salon_user_button" style={{width:"40%"}} onClick={purchasePlan}>Stake Now!</button>  
                :
                <button type="button" class="btn salon_user_button" style={{width:"40%"}} disabled>Stake Now!</button>  
                }
                <p style={{fontWeight:"700"}}>{msg}</p> 
                </div>
            </div>
        </Modal.Body>
      </Modal>
		</div> 
	); 
} 
export default Stake;