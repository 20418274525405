import React, { useState } from 'react'; 
import Logo from '../../Images/logo.png'
import Form from './ForgotPasswordForm'
import '../../CSS/Style.css'
const ForgotPassword = () => { 
	return ( 
		<div className='first_sec_ran'>
		<div className='container'> 
        <Form/>
		</div> 
		</div> 
	); 
} 
export default ForgotPassword;