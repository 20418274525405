import React, { useState,useEffect } from 'react'; 
import '../../CSS/Signup.css'
import axios from 'axios'
import validator from 'validator'
import Logo from '../../Images/logo.png'
var CryptoJS = require("crypto-js");
const SignupForm = () => { 
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [cpass, setCpass] = useState('');
      const [msg, setMsg] = useState('');
      const [phone, setPhone] = useState('');
      const [ref, setRef] = useState('');
      const [checkbox, setCheckBox] = useState(false);
      const [referralInputEnabled, setreferralInputEnabled] = useState(true);
      const [showPassword, setShowPassword] = useState(false);
      const [showPasswordC, setShowPasswordC] = useState(false);

      useEffect(() => {
        var token = window.location.href.split('?')[1]
      // console.log(token)
      if(!token){
        setreferralInputEnabled(true)
      }
      else{
        setRef(token)
        setreferralInputEnabled(false)
      }
      },[]);

      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

      const togglePasswordVisibilityC = () => {
        setShowPasswordC(!showPasswordC);
      };

      const handleCreate = ()=>{
            setMsg(<i class="fa fa-circle-o-notch fa-spin mt-2" style={{fontSize:'30px',color:"#fff"}}></i>)
            if(email.length>0 && password.length>0 && cpass.length>0){
                if (validator.isStrongPassword(password, {
                  minLength: 8, minLowercase: 1,
                  minUppercase: 1, minNumbers: 1, minSymbols: 1
              })) {
                if(password.length<=100){
                  if(password===cpass){
                  var encryptedemail = CryptoJS.AES.encrypt(email,"key")
                  var encryptedpass = CryptoJS.AES.encrypt(password,"key")
                  axios.post("https://backend.aitradeandstake.com/api/signup",{
                    encryptedemail:encryptedemail.toString(),
                    encryptedpass:encryptedpass.toString(),
                    phone:phone,
                    referred_by_id:ref
                  })
                  .then(res=>{
                      if(res.data==='Please enter a valid email address'){
                        setMsg(res.data)
                      }
                      else if(res.data==='User Already Exists'){
                        setMsg(res.data)
                      }
                      else{
                        setMsg(res.data)
                        setTimeout(()=>{
                              window.location.href="/"
                          },2000)
                      }
                  })
                  .catch(err=>{
                    setMsg("Something Went Wrong")
                  })
                }
                else{
                  setMsg("Password doesn't match")
                }
              }
              else{
                setMsg("Maximum password length is 100")
              }
              }
              else {
                  setMsg("Password must have combination of alphanumeric and special character and should be minimum 8 characters")
            }
            }
            else{
                  setMsg('Please enter all fields correctly!')
            }
        }
	return ( 
    <div className='parent' style={{position:"relative",height:"100vh"}}>
		<div className='child_class p-4' style={{backgroundColor:"#212531",border:"1px solid #252936",color:"#fff",borderRadius:"6px"}}> 
    <div className='signup_Text'>
        {/* <span className=''>Hi!{"\n"} Welcome</span> */}
        <img src={Logo} style={{width:"90%"}}/>
        </div>
        <br/>
        <div style={{textAlign:"left"}}>
        <p>Let's create an account</p>
        </div>
            <div class="form__group field">
            <input type="input" class="form__field" placeholder="Name" onChange={(e)=>setEmail(e.target.value)}  id='name' required />
            <label for="name" class="form__label">Email</label>
            </div>
            <div class="form__group field">
            <input type="number" class="form__field" placeholder="Phone" onChange={(e)=>setPhone(e.target.value)} id='name' required />
            <label for="name" class="form__label">Phone</label>
            </div>
            <div class="form__group field">
              {
                referralInputEnabled?
                <input type="text" class="form__field" placeholder="Referral" onChange={(e)=>setRef(e.target.value)} id='name' required />
                :
                <input type="text" class="form__field" placeholder="Referral" value={ref} required disabled/>
              }
            <label for="name" class="form__label">Referral ID(Optional)</label>
            </div>
            <div class="form__group field">
            <input type={showPassword?'text':'password'} class="form__field" placeholder="Name" onChange={(e)=>setPassword(e.target.value)} name="name" id='name' required />
            {showPassword?
              <i class="fa fa-eye" onClick={togglePasswordVisibility} style={{position:"absolute",top:"48%",right:"5px",color:"#fcbc0b",cursor:"pointer"}} aria-hidden="true"></i>
              :
              <i class="fa fa-eye-slash" onClick={togglePasswordVisibility} style={{position:"absolute",top:"48%",right:"5px",color:"#fcbc0b",cursor:"pointer"}} aria-hidden="true"></i>
          }
            <label for="name" class="form__label">Password</label>
            </div>
            <div class="form__group field">
            <input type={showPasswordC?'text':'password'} class="form__field" placeholder="Name" onChange={(e)=>setCpass(e.target.value)} name="name" id='name' required />
            {showPasswordC?
              <i class="fa fa-eye" onClick={togglePasswordVisibilityC} style={{position:"absolute",top:"48%",right:"5px",color:"#fcbc0b",cursor:"pointer"}} aria-hidden="true"></i>
              :
              <i class="fa fa-eye-slash" onClick={togglePasswordVisibilityC} style={{position:"absolute",top:"48%",right:"5px",color:"#fcbc0b",cursor:"pointer"}} aria-hidden="true"></i>
          }
            <label for="name" class="form__label">Confirm Password</label>
            </div>
            <div class="form__group" style={{display:"flex",justifyContent:"left"}}>
            <input type="checkbox" onClick={() => setCheckBox(!checkbox)}/> &nbsp;
                      <label
                        class="form-check-label"
                        for="flexCheckDefault"
                        style={{ color: "" }}
                      >
                        Sign up apply to the acceptance of our Terms and Conditions.
                      </label>
            </div>
            <div class="form__group field">
              {
                checkbox?
                <button type="button" class="btn" onClick={handleCreate} style={{minWidth:"50%",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}}>Signup</button>
                :
                <button type="button" class="btn" style={{minWidth:"50%",border:"1px solid #fcbc0b",backgroundColor:"#212531",color:"#fcbc0b"}} disabled>Signup</button>
              }
            {/* <button type="button" class="btn btn-lg w-100 color_button" onClick={handleCreate}>Signup</button> */}
            <br/>
            <p>{msg}</p>
            <br/>
            <p>Have an account?<a href="signin" style={{textDecoration:"none",color:"#fcbc05"}}><span style={{fontWeight:"800"}}> Login</span></a></p>
            </div>
		</div> 
    </div>
	); 
} 
export default SignupForm;