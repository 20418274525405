import React, { useState,useEffect } from 'react'; 
import Header from './Header'
import QRCode from "react-qr-code";
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import copy from "copy-to-clipboard";



const Wallet = () => { 
    const [offset, setoffset] = useState(0);
    const [data, setdata] = useState([]);
    const [perPage, setperPage] = useState(10);
    const [pageCount, setpageCount] = useState(0);
    const [currentPage, setcurrentPage] = useState(0);
    const [postDataa, setpostDataa] = useState('');

    const [offsetw, setoffsetw] = useState(0);
    const [dataw, setdataw] = useState([]);
    const [perPagew, setperPagew] = useState(10);
    const [pageCountw, setpageCountw] = useState(0);
    const [currentPagew, setcurrentPagew] = useState(0);
    const [postDataaw, setpostDataaw] = useState('');

    const [addresstrx, setaddresstrx] = useState("00");
    const [addresseth, setaddresseth] = useState("00");
    const [balance, setBalance] = useState(0);
    const [amount, setAmount] = useState('');
    const [txn_hash, settxn_hash] = useState('');
    const [network, setnetwork] = useState('');
    const [msg, setMsg] = useState('');
    const [msgWithdraw, setMsgWithdraw] = useState('');
    const [show, setShow] = useState(false);
    const [showwithdraw, setShowwithdraw] = useState(false);
    const [disableBtn, setdisableBtn] = useState(false);
    const [disableBtnWithdraw, setdisableBtnWithdraw] = useState(false);
    const [amountwithdraw, setAmountwithdraw] = useState('');
    const [calculatedWithdraw, setcalculatedWithdraw] = useState(0);
    const [walletwithdraw, setWalletwithdraw] = useState('');
    const [networkwithdraw, setnetworkwithdraw] = useState('');

    const [refid, setRefid] = useState('');
    const [copied, setCopied] = useState(false);
    const [copiedtrx, setCopiedtrx] = useState(false);
    const [copiedrefid, setcopiedrefid] = useState(false);
    const [ref_earning, setref_earning] = useState(0);

    const referral_data = (ref_id) =>{
        if(!ref_id){
            setref_earning(0)
        }
        else{
            let token = sessionStorage.getItem("token")
            let email = sessionStorage.getItem("email")
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            axios.post("https://backend.aitradeandstake.com/api/all_referral_bonus",{
                email:email,
                referralId:ref_id
            },config)
            .then(res=>{
                try{
                    setref_earning(res.data.totalBonus)

                }
                catch{
                    setref_earning(0)
                }
            })
        }
    }

    const withdrawCalc = (data) =>{
        setAmountwithdraw(data)
        if(!data){
            setcalculatedWithdraw(0)
        }
        else{
            setcalculatedWithdraw((parseFloat(data)-(parseFloat(data)*10/100)).toFixed(2))
        }
    }
    
    const copyRefId = (id) =>{
        setcopiedrefid(true)
        copy(id)
        setTimeout(()=>{
            setcopiedrefid(false)
        },2000)
    }
    const copyAddrTrx = (address) =>{
        setCopiedtrx(true)
        copy(address)
        setTimeout(()=>{
            setCopiedtrx(false)
        },2000)
    }
    const copyAddr = (address) =>{
        setCopied(true)
        copy(address)
        setTimeout(()=>{
            setCopied(false)
        },2000)
      }

    const handleClose = () =>{
        setShowwithdraw(false)
        setShow(false);
        setMsgWithdraw('')
        setMsg('')
    }
    const handleShow = () => {
        setShow(true);
    }
    const handleShowWithdraw = () => {
        setShowwithdraw(true);
    }
    useEffect(() => {
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
              if(!token || !email){
                  window.location.href="/"
              }
              else{
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.get("https://backend.aitradeandstake.com/api/validateToken",config)
                .then(async(res)=>{
                  if(res.data==='User validation failed'){
                    sessionStorage.clear();
                    window.location.href='/'
                  }
                  else{
                    setRefid(res.data.data._id)
                    axios.post("https://backend.aitradeandstake.com/api/get_user_balance",{email:email},config)
                    .then(data=>{
                        setBalance(parseFloat(data.data))
                    })
                    axios.post("https://backend.aitradeandstake.com/api/get_wallet_address_for_deposit",{email:email},config)
                    .then(data=>{
                        setaddresstrx(data.data)
                    })
                    
                    receiveData();
                    receiveDataWithdraw();
                    await referral_data(res.data.data._id)
                  }
                })
              }
  },[]);

  const receiveDataWithdraw = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_withdraw_req",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        const slice = data.slice(offsetw, offsetw+ perPagew)
        const postData= slice.map(index => {
            const objectId = index._id;
            const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
            const dateFromObjectId = new Date(timestamp);
            if(index.status==='Approved'){
                return(
                    <>
                    <React.Fragment>
                    <tbody>
                        <tr>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>{index.amount}</td>
                        <td>{index.network}</td>
                        <td>{dateFromObjectId.toLocaleDateString()}</td>
                        <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>{index.status}</td>
                        </tr>
                    </tbody>
                    </React.Fragment>
                    </>
                )
            }
            else{
                return(
                    <>
                    <React.Fragment>
                    <tbody>
                        <tr>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>{index.amount}</td>
                        <td>{index.network}</td>
                        <td>{dateFromObjectId.toLocaleDateString()}</td>
                        <td style={{color:"orange",fontWeight:"700",fontSize:"13px"}}>{index.status}</td>
                        </tr>
                    </tbody>
                    </React.Fragment>
                    </>
                )
            }
        })
        setpageCountw(Math.ceil(data.length / perPagew))
        setpostDataaw(postData)
    })
  }

  const receiveData = () =>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    axios.post("https://backend.aitradeandstake.com/api/get_user_deposit_request",{email:email},config)
    .then(res=>{
        const data = res.data.reverse();
        const slice = data.slice(offset, offset+ perPage)
        const postData= slice.map(index => {
            const objectId = index._id;
            const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
            const dateFromObjectId = new Date(timestamp);
            if(index.status==='Approved'){
                return(
                    <>
                    <React.Fragment>
                    <tbody>
                        <tr>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>{index.amount}</td>
                        <td>{index.network}</td>
                        <td>{dateFromObjectId.toLocaleDateString()}</td>
                        <td style={{color:"green",fontWeight:"700",fontSize:"13px"}}>{index.status}</td>
                        </tr>
                    </tbody>
                    </React.Fragment>
                    </>
                )
            }
            else{
                return(
                    <>
                    <React.Fragment>
                    <tbody>
                        <tr>
                        <th>{index._id.slice(0,4)+'..'+index._id.slice(20,24)}</th>
                        <td style={{color:"#fcbc05"}}>{index.amount}</td>
                        <td>{index.network}</td>
                        <td>{dateFromObjectId.toLocaleDateString()}</td>
                        <td style={{color:"orange",fontWeight:"700",fontSize:"13px"}}>{index.status}</td>
                        </tr>
                    </tbody>
                    </React.Fragment>
                    </>
                )
            }
        })
        setpageCount(Math.ceil(data.length / perPage))
        setpostDataa(postData)
    })
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;
    setcurrentPage(selectedPage)
    setoffset(offset)
    receiveData()
};
const handlePageClickW = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPagew;
    setcurrentPagew(selectedPage)
    setoffsetw(offset)
    receiveDataWithdraw()
};

const Withdraw = () =>{
    setdisableBtnWithdraw(true)
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    setMsgWithdraw(<i class="fa fa-circle-o-notch fa-spin mt-2" style={{fontSize:'30px'}}></i>)
    if(parseFloat(amountwithdraw)>=10){
        if(walletwithdraw.length>0 && networkwithdraw.length>0){
            axios.post("https://backend.aitradeandstake.com/api/withdraw_req",{
                email:email,
                wallet_address:walletwithdraw,
                network:networkwithdraw,
                amount:amountwithdraw
            },config)
            .then(res=>{
                setMsgWithdraw(res.data)
                setdisableBtnWithdraw(false)
            })
        }
        else{
            setMsgWithdraw("Please enter all details")
            setdisableBtnWithdraw(false)
        }
    }
    else{
        setMsgWithdraw("Minimum withdraw amount is $ 10")
        setdisableBtnWithdraw(false)
    }
}

  const Deposit = () =>{
    setdisableBtn(true)
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    setMsg(<i class="fa fa-circle-o-notch fa-spin mt-2" style={{fontSize:'30px'}}></i>)
    if(parseFloat(amount)>=100){
        if(txn_hash.length>0 && network.length>0){
            axios.post("https://backend.aitradeandstake.com/api/deposit_req",{
                email:email,
                txn_hash:txn_hash,
                network:network,
                amount:amount
            },config)
            .then(res=>{
                setMsg(res.data)
                setdisableBtn(false)
            })
        }
        else{
            setMsg("Please enter all details")
            setdisableBtn(false)
        }
    }
    else{
        setMsg("Minimum deposit amount is $ 100")
        setdisableBtn(false)
    }
  }
	return ( 
        <>
        <Header/>
		<div className='container my-3'> 
      
        <div className='row mt-3'>
            <div className='col-md-4 mt-1'>
            <div class="card" style={{width: "100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body for_shadow">
            <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                {/* <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">TRC</button> */}
                {/* <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">ETH</button> */}
            </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <h5 class="card-title mt-3" style={{fontWeight:"700"}}>DEPOSIT USDT (TRC-20) HERE</h5>
                    <QRCode 
                    size={250}
                    style={{ height: "20vh", maxWidth: "100%", width: "100%" }} 
                    value={addresstrx}
                    viewBox={`0 0 256 256`}
                    bgColor={"#000"}
                    fgColor={"#fff"}
                    />
                    <br/> <br/>
                    <p style={{fontWeight:"700",color:"#fcbc0b"}}>{addresstrx}
                    {
                            copiedtrx?
                            <>
                            <i class="fa fa-copy" onClick={()=>copyAddrTrx(addresstrx)} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer",color:"#5b90f4"}} aria-hidden="true"></i>
                            <span style={{color:"#5b90f4",fontSize:"12px",marginLeft:"5px"}}>Copied!</span>
                            </>
                            :
                            <i class="fa fa-copy" onClick={()=>copyAddrTrx(addresstrx)} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer"}} aria-hidden="true"></i>
                        }
                    </p>
                    <div className='p-2' style={{backgroundColor:"#232a3c",borderRadius:"6px"}}>
                    <span style={{color:"#476fb8",fontWeight:"600",fontSize:"12px"}}><span style={{color:"#5b90f4"}}>Note</span> : Deposit only USDT(TRC-20) tokens,Depositing a coin type and/or via a blockchain not listed may result in the permanent loss of your coin.</span>
                    </div>
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <h5 class="card-title mt-3" style={{fontWeight:"700"}}>Deposit USDT (ERC-20) Here</h5>
                    <QRCode 
                    size={250}
                    style={{ height: "20vh", maxWidth: "100%", width: "100%" }} 
                    value={addresseth}
                    viewBox={`0 0 256 256`}
                    bgColor={"#000"}
                    fgColor={"#fff"}
                    />
                    <br/> <br/>
                    <p style={{fontWeight:"700",color:"#fcbc0b"}}>
                        {addresseth} 
                        {
                            copied?
                            <>
                            <i class="fa fa-copy" onClick={()=>copyAddr(addresseth)} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer",color:"#5b90f4"}} aria-hidden="true"></i>
                            <span style={{color:"#5b90f4",fontSize:"12px",marginLeft:"5px"}}>Copied!</span>
                            </>
                            :
                            <i class="fa fa-copy" onClick={()=>copyAddr(addresseth)} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer"}} aria-hidden="true"></i>
                        }
                    </p>
                    <div className='p-2' style={{backgroundColor:"#232a3c",borderRadius:"6px"}}>
                    <span style={{color:"#476fb8",fontWeight:"600",fontSize:"12px"}}><span style={{color:"#5b90f4"}}>Note</span> : Deposit only USDT(ERC-20) tokens,Depositing a coin type and/or via a blockchain not listed may result in the permanent loss of your coin.</span>
                    </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div className='col-md-4 mt-1'>
            <div class="card" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body for_shadow" style={{position:"relative"}}>
            <div style={{position:"relative",top:"50%",transform:"translateY(-50%)"}}>
                <h5 class="card-title" style={{fontWeight:"700"}}>AVAILABLE FUND</h5>
                <span class="card-text" style={{fontWeight:"900",fontSize:"22px",color:"#fcbc0b"}}>$ {balance.toFixed(2)}</span>
                <br/><br/>
                <button type="button" class="btn salon_user_button" style={{marginRight:"5px"}} onClick={handleShow}>INVEST NOW</button>
                <button type="button" class="btn salon_user_button" onClick={handleShowWithdraw}>WITHDRAW NOW</button>
            </div>
            </div>
            </div>
                </div>
                <div className='col-md-4 mt-1'>
            <div class="card" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body for_shadow" style={{position:"relative"}}>
                <div style={{position:"relative",top:"50%",transform:"translateY(-50%)"}}>
                <h5 class="card-title" style={{fontWeight:"700"}}>YOUR REFERRAL ID</h5>
                <QRCode 
                    size={250}
                    style={{ height: "14vh", maxWidth: "100%", width: "100%" }} 
                    value={refid?refid:''}
                    viewBox={`0 0 256 256`}
                    bgColor={"#000"}
                    fgColor={"#fff"}
                    />
                    <br/>
                    <br/>
                <p style={{color:"#fcbc0b",fontWeight:"700"}}>{refid?refid:''}
                {
                            copiedrefid?
                            <>
                            <i class="fa fa-copy" onClick={()=>copyRefId(refid?refid:'')} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer",color:"#5b90f4"}} aria-hidden="true"></i>
                            <span style={{color:"#5b90f4",fontSize:"12px",marginLeft:"5px"}}>Copied!</span>
                            </>
                            :
                            <i class="fa fa-copy" onClick={()=>copyRefId(refid?refid:'')} style={{marginLeft:"5px",fontSize:"20px",cursor:"pointer"}} aria-hidden="true"></i>
                        }
                </p>
                <p style={{color:"#fff",fontWeight:""}}>
                    <span >REFERRAL EARNINGS : </span>
                    <span style={{color:"#fcbc0b"}}> $ {ref_earning?ref_earning:0}</span>
                </p>
                </div>
            </div>
            </div>
                </div>
        </div>
        <div className='row'>
            <div className='col-md-6'>
            <div class="card mt-4" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body for_shadow">
                <h5 class="card-title" style={{fontWeight:"700"}}>DEPOSIT HISTORY</h5>
                <div className='table-responsive'style={{borderRadius:"12px"}}>
                <table class="table table-dark">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Network</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                    </tr>
                </thead>
               {postDataa}
                </table>
                <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} style={{float:"right"}}/>
                </div>
                <div className='p-2' style={{backgroundColor:"#232a3c",borderRadius:"6px"}}>
                    <span style={{color:"#476fb8",fontWeight:"600",fontSize:"12px"}}><span style={{color:"#5b90f4"}}>Note</span> : 
                    Please allow up to 24 hours for your deposit to be verified and processed. We appreciate your patience as we ensure 
                    the accuracy and security of your transaction.</span>
                    </div>
            </div>
            </div>
            </div>
            <div className='col-md-6'>
            <div class="card mt-4" style={{width: "100%",height:"100%",backgroundColor:"#17161b",border:"1px solid #252936",color:"#fff"}}>
            <div class="card-body for_shadow">
                <h5 class="card-title" style={{fontWeight:"700"}}>WITHDRAW HISTORY</h5>
                <div className='table-responsive' style={{borderRadius:"12px"}}>
                <table class="table table-dark">
                <thead>
                    <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Network</th>
                    <th scope="col">Time</th>
                    <th scope="col">Status</th>
                    </tr>
                </thead>
                {postDataaw}
                </table>
                <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCountw}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickW}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} style={{float:"right"}}/>
            </div>
                    <div className='p-2' style={{backgroundColor:"#232a3c",borderRadius:"6px"}}>
                    <span style={{color:"#476fb8",fontWeight:"600",fontSize:"12px"}}><span style={{color:"#5b90f4"}}>Note</span> : 
                    Kindly note that withdrawals may take up to 24 hours for verification. Your patience is valued as we prioritize the 
                    security and accuracy of each transaction.</span>
                    </div>
            </div>
            </div>
            </div>
        </div>


        <div className='mt-5'>
				<div class="card p-4" style={{textAlign:"left",width: "100%",height:"100%",borderRadius:"12px",backgroundColor:"#212531",border:"1px solid #252936",color:"#fff"}}>
					<div className='row'>
                        <div className='col-md-3 mt-1'>
							<p style={{fontWeight:"800",color:"#fcbc05"}}>
							Step 1: Deposit USDT to the Provided Wallet Address
							</p>
							<span style={{color:"#adadae"}}>
							Deposit USDT to the provided wallet address, ensuring the exact specified amount. Double-check the address 
                            for accuracy to avoid transaction errors.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
							<p style={{fontWeight:"800",color:"#fcbc05"}}>
							Step 2: Enter Transaction ID and Amount for Validation
							</p>
							<span style={{color:"#adadae"}}>
                            Enter the Transaction ID (Txn ID) generated during your USDT deposit.<br/>
                            Specify the exact amount you deposited.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
                            <p style={{fontWeight:"800",color:"#fcbc05"}}>
                            Step 3: Submit Your Request
                            </p>
							<span style={{color:"#adadae"}}>
							After entering the required details, submit your request for transaction validation. Our admin team will 
                            review and validate your deposit.
							</span>
						</div>
						<div className='col-md-3 mt-1'>
                            <p style={{fontWeight:"800",color:"#fcbc05"}}>
                            Step 4: Confirmation and Approval
							</p>
							<span style={{color:"#adadae"}}>
							Upon successful validation, expect prompt confirmation of your deposit. Your account will swiftly be credited 
                            with the corresponding USDT amount, allowing you to explore investment opportunities on our platform. We aim to 
                            complete this process within an estimated 24 hours.
							</span>
						</div>
					</div>
				</div>
			</div>

		</div> 
        <Modal 
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered      
            show={show} onHide={handleClose}>
            <Modal.Header>
            <Modal.Title>Deposit Request</Modal.Title>
            <i class="fa fa-times" style={{cursor:"pointer"}} onClick={handleClose} aria-hidden="true"></i>
            </Modal.Header>
            <Modal.Body>
            <div className=''>
            <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Amount to deposit</label>
            <input type="number" class="form-control" onChange={(e)=>setAmount(e.target.value)} placeholder="Enter amount"/>
            </div>
            <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Transaction hash</label>
            <input type="text" class="form-control" onChange={(e)=>settxn_hash(e.target.value)} placeholder="Enter txn hash"/>
            </div>
            <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Choose Network</label>
            <select class="form-select" onChange={(e)=>setnetwork(e.target.value)} aria-label="Default select example">
            <option selected>Choose Network</option>
            <option value="TRX">TRX</option>
            {/* <option value="ETH">ETH</option> */}
            {/* <option value="3">Three</option> */}
            </select>
            </div>
                    <center>
                    {disableBtn?
                   <button type="button" class="btn salon_user_button" disabled>Submit</button>
                   :
                   <button type="button" class="btn salon_user_button" onClick={Deposit}>Submit</button>
                    }
                   <p>{msg}</p>
                   </center>
            </div>
            </Modal.Body>
      </Modal>

      <Modal 
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered      
            show={showwithdraw} onHide={handleClose}>
            <Modal.Header>
            <Modal.Title>Withdraw Request</Modal.Title>
            <i class="fa fa-times" style={{cursor:"pointer"}} onClick={handleClose} aria-hidden="true"></i>
            </Modal.Header>
            <Modal.Body>
            <div className=''>
            <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Amount to withdraw</label>
            <input type="number" class="form-control" onChange={(e)=>withdrawCalc(e.target.value)} placeholder="Enter amount"/>
            <small style={{fontSize:"10px",fontWeight:"700"}}>You will get : {calculatedWithdraw} (Withdrawal Fees 10%)</small>
            </div>
            <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Wallet to Withdraw</label>
            <input type="text" class="form-control" onChange={(e)=>setWalletwithdraw(e.target.value)} placeholder="Enter wallet address"/>
            </div>
            <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Choose Network</label>
            <select class="form-select" onChange={(e)=>setnetworkwithdraw(e.target.value)} aria-label="Default select example">
            <option selected>Choose Network</option>
            <option value="TRX">TRX</option>
            {/* <option value="ETH">ETH</option> */}
            {/* <option value="3">Three</option> */}
            </select>
            </div>
                    <center>
                    {disableBtnWithdraw?
                   <button type="button" class="btn salon_user_button" disabled>Submit</button>
                   :
                   <button type="button" class="btn salon_user_button" onClick={Withdraw}>Submit</button>
                    }
                   <p>{msgWithdraw}</p>
                   </center>
            </div>
            </Modal.Body>
      </Modal>
        </>
	); 
} 
export default Wallet;